<template>
	<div>
		<v-navigation-drawer
			app
			dark
            v-model="openDrawer"
            :clipped="true"
            class="scrollbar-none"
        >
            <v-list
				dense
				nav
				expand
			>
                <list-tree 
                    v-for="(node, i) in toolbarItems" 
                    :key="i" 
                    :node="node"
                    :first='true'
                />
            </v-list>
        </v-navigation-drawer>

		<v-app-bar
            clipped-left
            dark
            app
            class='blue darken-1'
        >
			<v-app-bar-nav-icon @click="openDrawer = !openDrawer"/>

			<v-toolbar-title>
				<span class="font-weight-light">
					Portal de Serviços
				</span>
			</v-toolbar-title>

			<v-spacer/>

			<v-toolbar-title>
				<div class="font-weight-light mr-4">
					Olá, {{ formattedName }}
				</div>
				<div
					style="font-size: 0.7em; font-weight: normal;"
					class="font-weight-bolder mr-4"
				>
					Último Acesso {{ userAccessTime }}
				</div>
			</v-toolbar-title>

			<v-menu
				rounded 
				bottom
				min-width="200px"
				offset-y
			>
				<template v-slot:activator="{ on }">
					<v-btn
						icon
						x-large v-on="on"
					>
						<v-avatar
							color='indigo'
							size="48"
						>
							<v-img
								v-if='userPhotoUrl'
								:src="userPhotoUrl"
							/>
							<v-icon v-else>
								mdi-account
							</v-icon>
						</v-avatar>
					</v-btn>
				</template>

				<v-card>
					<v-list-item-content class="justify-center">
						<div class="mx-auto text-center">
							<v-avatar
                                color='indigo'
                                size="70"
                            >
								<v-img
									v-if='userPhotoUrl'
									:src="userPhotoUrl"
								/>
								<v-icon
									v-else
									dark
									x-large
								>
									mdi-account
								</v-icon>
							</v-avatar>

							<h4 class="pl-5 pr-5">
								{{ userData.Nome }}
							</h4>
							<p class="caption mt-1">
								{{ userData.Email }}
							</p>
							<div class="caption mt-1">
								Último Acesso
							</div>
							
							<p> {{ userAccessTime }} </p>
							<v-divider class="my-3"/>

							<v-btn
								text
								block
								style="text-decoration: none;"
								:to="'/About'"
							>
								Meus Dados
							</v-btn>

							<v-divider class="my-3"/>

							<v-btn
								depressed
								rounded
								text
								block
								@click="logout"
							>
								Sair
							</v-btn>
						</div>
					</v-list-item-content>
				</v-card>
			</v-menu>
		</v-app-bar>
	</div>
</template>

<script>
import ListTree from './system/ListTree.vue';

export default {
	name: 'ToolbarSystem',

    components: { ListTree },

	data: () =>
	({
        userData: {},
        openDrawer: false,

		
		toolbarItems: [
			{ title: 'Início', icon: 'home-outline', path: '/home' },
			{ title: 'Validar documento', icon: 'check', path: '/requerimentos/validar' },
			{ title: 'Propagandas Institucionais', icon: 'video', path: '/videos' },
			{ title: 'Materiais', icon: 'package-variant', children:
				[
					{ title: 'Dispensações', icon: 'package-variant', path: '/saude/materiais'},
					{ title: 'Ministerio da saúde', icon: 'account-search-outline', path: '/saude/MinisterioSaude'},
					
				]
			},
			{ title: 'Educação', icon: 'school', children:
				[
					{ title: 'Avaliações', icon: 'account-search-outline', path: '/educacao/avaliacao' },
					{ title: 'Relação de diretores', icon: 'account-tie-outline', path: '/educacao/diretorvice' },
					{ title: 'Painel educacional', icon: 'monitor-dashboard', path: '/educacao/monitor' },
					{ title: 'Área do(a) estudante', icon: 'account-school', children:
						[
							{ title: 'Minhas aulas', icon: 'calendar-text', path: '/aluno/aulas' },
							{ title: 'Boletim', icon: 'table-search', path: '/aluno/boletim' },
							{ title: 'Frequência', icon: 'calendar-cursor', path: '/aluno/frequencia' }
						]
					},
					{ title: 'Área do(a) docente', icon: 'account-school', children:
						[
							// { title: 'Minhas turmas', icon: 'calendar-text', path: '' },
							{ title: 'Currículo', icon: 'table-search', path: '/professor/curriculodocente' },
						]
					},
					{ title: 'Área da direção', icon: 'account-tie-outline', children:
						[
							{ title: 'Educacenso', icon: 'account-tie-outline', path: '/educacao/Educacenso' },	
							{ title: 'Painel da direção', icon: 'monitor-dashboard', path: '/direcao' },
							{ title: 'Relação de RH', icon : 'account-group-outline', path: '/funcionarios' },
						]
					}
				]
			},
			{ title: 'Saúde', icon: 'hand-heart', children:
				[
					//{ title: 'Atenção primária', icon: 'hospital-box-outline', path: '/saude/indicadores' },
					{ title: 'Produção médicos(as)', icon: 'doctor', path: '/saude/medicos'  },
					{ title: 'Produção de enfermagem', icon: 'needle', path:'/saude/enfermeiros'  },
					{ title: 'Producação BPA', icon: 'chart-line', path: '/saude/producaoBPA' },
					{ title: 'Painéis', icon: 'monitor-dashboard', children:
						[
							{ title: 'Painel ambulatorial', icon: null, path: '/saude/ambulatorial' },
							{ title: 'Painel emergencial', icon: null, path: '/saude/monitor' },
							{ title: 'Painel epidemiológico', icon: null, path: '/saude/dashboard' }
							//{ title: 'Painel hospitalar', icon: null, path: '' },
						]
					}
				]
			},
			{ title: 'Financeiro', icon: 'currency-usd', children: 
				[
					{ title: 'Folha de pagamento', icon: 'file-chart-outline', path: '/financeiro/folha' },
					{ title: 'Despesas e receitas', icon: 'chart-line', path: '/financeiro/topdown' }
				]
			},
			{ title: 'Assistência', icon: 'account', children:
				[
					{ title: 'Cadastro Habitacional', icon: 'note-text-outline', path: '/assistencia/formularios' },
				]
			},
			{ title: 'Cidadão', icon: 'account', children:
				[
					{ title: 'Exames', icon: 'flask-plus-outline', path: '/saude/exames' },
					{ title: 'Atestados', icon: 'note-text-outline', path: '/saude/atestado' },
					{ title: 'Dispensações', icon: 'pill', path: '/saude/dispensacao' },
					{ title: 'Atendimentos', icon: 'stethoscope', path: '/saude/atendimento' }
				]
			}
		]
	}),

	computed: {
		userAccessTime()
		{
			return sessionStorage.getItem('acesso');
		},
		userPhotoUrl()
		{
            if (!this.userData.Nome) return '';

			return `http://sis.macaiba.dti.systems/sis/cliente/fotos/${this.userData.IdPessoaFisica}.jpg`
		},
        formattedName()
        {
            if (!this.userData.Nome) return 'usuário';

            const firstName = this.userData.Nome.split(' ')[0];
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        }
	},

	methods: {
		logout()
		{
			sessionStorage.clear();
			location.href = '/';
		}
	},

    beforeCreate()
    {
        this.axios.get('usuario')
		.then(res => {
            this.userData = res.data;
        }); 
    }

}

</script>
