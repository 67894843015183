<template>
    <div>        
        <v-row>
            <v-col cols="6">

                <audio ref="audio">
                    <source src="@/static/bell-song.mp3" type="audio/mp3">
                </audio>
                <v-card height='500PX'>
                        <v-layout column>

                            <h4 v-if="NomePaciente" class="primary--text text-center font-weight-bold mt-10" style="font-size:100px">
                                {{ NomePaciente }}
                            </h4>
                            <h4 v-else class="primary--text text-center font-weight-bold mt-5" style="font-size:200px">
                                {{ Senha }}
                            </h4>
                            <h4 class="primary--text text-center font-weight-bold mt-5" style="font-size:50px">
                                {{ Local }}
                            </h4>                                
                        </v-layout>

                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card  height='500px' max-height="500px">                        
                    <iframe id="Video" width="100%" height="100%" src="https://www.youtube.com/embed/videoseries?list=PL12jx8elnGwbw8O-gUkqfvQuTc6UD3cvm&autoplay=1&mute=1&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen muted></iframe>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card  height='430' style="overflow-y:auto">
                    <h4 class="primary--text font-weight-bold pt-5" style="text-align: center !important">
                        ÚLTIMAS SENHAS CHAMADAS
                    </h4>
                    <v-data-table
                    :headers="headersSenhasChamadas"
                    :items="senhasChamadasList"
                    itemsPerRow="6"
                    class="chamadas"
                    >
                        <template v-slot:[`item.Senha`]=" { item } ">
                            <span class="primary--text" style="font-size: 2rem"> {{item.Senha}} </span>
                        </template>

                        <template v-slot:[`item.Nome`]=" { item } ">
                            <span class="primary--text" style="font-size: 3rem"> {{item.Nome}} </span>
                        </template>

                        <template v-slot:[`item.Local`]=" { item } "> 
                            <span class="primary--text" style="font-size: 2rem"> {{item.Local}} </span>
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
 
import { delay } from '@/utils/delay.js'

export default {
    watch: {
        init: {
            immediate: true,
            handler() {
                this.getUltimaFicha()
                this.getSenhasChamadas()                
            }
        }
    },
    data() {        
        return {
            audioEnabled: false,
            senhasChamadasList:[],            
            tocarBeep: null,
            NomePaciente: null,
            Local: null,            
            Senha: null,
            idUnidade: null,
            headersSenhasChamadas:[
                    {text:'Número da senha', value:'Senha', width:"10%", font:"40px", align:"center"},
                    {text:'Nome do paciente', value:'Nome', width:"50%", align:"left"},
                    {text:'Local', value:'Local', width:"20%", align:"left"},
                ],
            reload: null            
        }
    },
    methods: {   
        async getSenhasChamadas(){
            const { data } = await this.axios.get('painel/senhachamadaESF', { params: { idUnidade: this.$route.query.idUnidade } })
            this.senhasChamadasList = data
            setTimeout(this.getSenhasChamadas, 15000)
        },
        falarNome(nome){
            let msg = new SpeechSynthesisUtterance();
            msg.text = nome;
            window.speechSynthesis.speak(msg);
        },
        async getUltimaFicha(){
			const { data } = await this.axios.get('painel/ultimasenhachamadaESF', { params: { idUnidade: this.$route.query.idUnidade } })
            data.forEach(element => {
                this.Senha          = element.Senha
                this.NomePaciente   = element.Nome
                this.Local          = element.Local
                this.tocarBeep      = element.tocarBeep                 
            })

            if (this.tocarBeep) {
                this.$refs.audio.play()
                delay(1000);
                this.NomePaciente
                    ? this.falarNome(this.NomePaciente)
                    : this.falarNome('Senha ' + this.Senha)
                this.falarNome(this.Local)
            }
            setTimeout(this.getUltimaFicha, 15000);
		},        
    },
    mounted() {        
        setInterval(() => {
            location.reload();
        }, 900000); // 15 minutos
    },
}
</script>
<style scoped>
::v-deep .custom-data-table thead th {
  color: white !important;
  background-color: #2fb1e5 !important;
  font-size: 14px !important;
}
</style>

<style>
    .chamadas{
        size:8.4rem !important;
        color:blue;
    }
</style>