<template>
    <v-card elevation="5" class="mb-5" style="width: 100%">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Controle do estoque mínimo</h6>
        </div>
        <v-row class="m-2">
        </v-row>
        <v-row class="m-2">
            <v-col cols="12" sm="8" md="8">
                <v-text-field
                    v-model="search"
                    prepend-icon="mdi-feature-search-outline"
                    label="Pesquisar"
                    clearable
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <v-select
                    v-model="filters.situacao"
                    :items="situacaoItems"
                    label="Situação"
                    item-text="text"
                    item-value="value"
                    clearable
                ></v-select>
            </v-col>
        </v-row>
        <v-data-table
            v-model="expanded"
            :headers="headers"
            :items="estoqueMinimo"
            :search="search"
            item-value="name"
            class="elevation-1 custom-data-table"
            :loading="loading"
            loading-text="Carregando..."
        >
            <template v-slot:[`item.situacao`]="{ item }">
                <v-chip v-if="item.situacao === 'critico'" color="red" dark> Crítico </v-chip>
                <v-chip v-else-if="item.situacao === 'estoque_minimo'" color="orange"> Estoque mínimo </v-chip>
                <v-chip v-else> Confortável </v-chip>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>

export default {
    props :{
        idUnidade: {
            type: Number,
            required: false
        },
        idGrupo: {
            type: Number,
            required: false
        },
        idUnidadeTipo : {
            type: Number,
            required: false
        },
    },
    data() {
        return {
            expanded: [], // Adicionado para resolver o warning
            situacaoItems: [
                { text: 'Confortável', value: 'confortavel' },
                { text: 'Estoque Mínimo', value: 'estoque_minimo' },
                { text: 'Crítico', value: 'critico' }
            ],
            estoqueMinimo: [],
            estoqueCritico: [],
            estoqueMerged: [],
            loading: false,
            search: null,
            filters: {
                situacao: null
            },
            headers: [
                { text: 'Situação', value: 'situacao', width: '10%', align: 'center' },
                { text: 'Item', value: 'item', width: '30%', align: 'center' },
                { text: 'Unidade', value: 'unidade', width: '30%', align: 'center' },
                { text: 'Qnt Atual', value: 'qtdAtual', width: '10%', align: 'right' },
                { text: 'Qnt Mínima', value: 'QtdMinimaEstoque', width: '10%', align: 'right' },
                { text: 'Qnt Crítica', value: 'qtdCritica', width: '10%', align: 'right' }
            ],
        }
    },    
    methods: {
        async getEstoqueMinimo() {
            try {
                this.loading = true;
                const response = await this.axios.get('/estoque/minimo', {
                    params: {
                        IdUnidade: this.idUnidade,
                        Grupo: this.idGrupo,
                        IdUnidadeTipo: this.idUnidadeTipo
                    }
                 });
                this.estoqueMinimo = response.data;
                this.loading = false;
                
            } catch (error) {
                this.loading = false;
            }
        },
        async refreshSituacao(situacao) {
            this.filters.situacao = situacao;
            await this.getEstoqueMinimo();
            if(this.filters.situacao != null)
                this.estoqueMinimo = this.estoqueMinimo.filter(item => item.situacao === this.filters.situacao);
              
        },
        limparFiltros() {
            this.filters.situacao = null;
            this.search = null;
            this.getEstoqueMinimo();
        }
    },
    mounted() {
        console.log('Componente montado');
        this.getEstoqueMinimo();
    },
    watch: {
        'filters.situacao'(newVal) {
            this.refreshSituacao(newVal);
        },
        idGrupo() {
        this.getEstoqueMinimo();
        },
   
        idUnidade() {
        this.getEstoqueMinimo();
        },
        idUnidadeTipo() {
        this.getEstoqueMinimo();
        }
    }
}

</script>

<style scoped>
::v-deep .custom-data-table thead th {
    color: white !important;
    background-color: #2fb1e5 !important;
    font-size: 14px !important;
}
</style>