<template>
  <div>
    <div class="container-fluid" max-height="430" style="overflow-y:auto">
      <br />

      <v-row>
        <v-col cols="12">
          <v-card shaped elevation="1">
            <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
              PREFEITURA MUNICIPAL DE MACAÍBA - INDICADORES
            </h4>
            <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center">
              PAINEL DE INFORMAÇÕES DA EDUCAÇÃO
            </h5>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
          <div class="col-xl-6 col-md-12">
            <filtroAno @messageFromChild="recall"></filtroAno>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <card-indicadores :type="true" :color="'primary'" :icon="'school'" :label="'Quantidade de escolas'"
          :data="qtdEscolas" @click.native="handleNextCards('escolas')" />

        <card-indicadores :type="true" :color="'primary'" :icon="'users'" :label="'Quantidade de estudantes'"
          :data="qtdAlunos" @click.native="handleNextCards('alunos')" />

        <card-indicadores :type="true" :color="'primary'" :icon="'chalkboard-teacher'"
          :label="'Quantidade de professores'" :data="qtdProfessores" @click.native="handleNextCards('professores')" />

        <card-indicadores :type="true" :color="'primary'" :icon="'graduation-cap'" :label="'Quantidade de turmas'"
          :data="qtdTurmas" @click.native="handleNextCards('turmas')" />
      </v-row>

      <v-row>
        <div :class="
          $vuetify.breakpoint.mdAndDown
            ? 'col-xl-12 col-md-12 mb-2'
            : 'col-xl-6 col-lg-6 mb-2'
        ">
          <div class="card shadow mb-3" style="min-height: 400px;">
            <div class="
                card-header
                py-3
                d-flex
                align-items-center
                justify-content-between
              ">
              <h6 class="m-0 font-weight-bold text-primary">
                Calendário bimestral do ano letivo
              </h6>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span> Calendário letivo de aulas, por bimestre </span>
              </v-tooltip>
            </div>
            <div id="Calendario" class="card-body align-items-center justify-content-between">
              <calendario :idAno="idAno" :key="calendario"></calendario>
            </div>
          </div>
        </div>

        <div :class="
          $vuetify.breakpoint.mdAndDown
            ? 'col-xl-12 col-md-12'
            : 'col-xl-6 col-lg-6 mb-2'
        ">
          <div class="card shadow" style="max-height: 400px;">
            <div class="
                card-header
                py-3
                d-flex
                align-items-center
                justify-content-between
              ">
              <h6 class="m-0 font-weight-bold text-primary">
                Quantitativo de escolas por zona rural/urbana
              </h6>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  Veja o quantitativo de escolas por zona: rural e urbana
                </span>
              </v-tooltip>
            </div>
            <div v-if="!loadingEscolas" id="Pizza" class="card-body">
              <card-pie-educacao :data="escolas" :key="showPie"></card-pie-educacao>
            </div>
          <div v-else style="min-height: 400px;">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
              Carregando dados...
            </div>
          </div>
        </div>
      </v-row>
      <v-row>
        <div :class="
          $vuetify.breakpoint.mdAndDown
            ? 'col-xl-12 col-md-12 mb-2'
            : 'col-xl-6 col-lg-6 mb-2'
        ">
          <div class="card shadow mb-3">
            <div class="
                card-header
                py-3
                d-flex
                align-items-center
                justify-content-between
              ">
              <h6 class="m-0 font-weight-bold text-primary">
                Resumo dos estudantes que utilizam transporte escolar
              </h6>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span> Resumo dos estudantes que utilizam transporte escolar</span>
              </v-tooltip>
            </div>
            <div v-if="!loadingTransporteEscolarChart" id="chart1">
              <apexchart type="bar" height="350" :options="chartOptionsTransporteEscolar" :series="seriesTransportes" :key="showTransporteEscolar"></apexchart>
            </div>
            <div v-else style="min-height: 400px;">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
              Carregando dados...
            </div>
          </div>
        </div>
        <div :class="
        $vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12'
          : 'col-xl-6 col-lg-6 mb-2'
      ">
        <div class="card shadow">
          <div class="
              card-header
              py-3
              d-flex
              align-items-center
              justify-content-between
            ">
            <h6 class="m-0 font-weight-bold text-primary">
              Escolas com estudantes que utilizam transporte escolar
            </h6>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>
                Escolas com estudantes que utilizam transporte escolar
              </span>
            </v-tooltip>
          </div>
          <div id="chart2">
            <v-data-table 
            height="305"  
            :items="unidadesTransporteEscolarList" 
            :headers="headersUnidadesAlunosEspeciais" 
            :items-per-page="5"
            :loading="loadingTransporteEscolar"
            class="elevation-1 custom-data-table"
            >
            </v-data-table>
          </div>
        </div>
      </div>
    </v-row>

      <v-row>
        <div :class="
          $vuetify.breakpoint.mdAndDown
            ? 'col-xl-12 col-md-12 mb-2'
            : 'col-xl-6 col-lg-6 mb-2'
        ">
          <div class="card shadow mb-3">
            <div class="
                card-header
                py-3
                d-flex
                align-items-center
                justify-content-between
              ">
              <h6 class="m-0 font-weight-bold text-primary">
                Resumo dos(as) estudantes com deficiência, transtorno do espectro autista, altas habilidades
              </h6>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span> Resumo dos(as) estudantes com deficiência, transtorno do espectro autista, altas habilidades</span>
              </v-tooltip>
            </div>
            <div v-if="!loadingDeficiencia" id="chart1">
              <apexchart type="bar" height="350" :options="chartOptionsAlunos" :series="seriesAlunos" :key="showAlunosDeficientes"></apexchart>
            </div>
            <div v-else style="min-height: 400px;">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
              Carregando dados...
            </div>
          </div>
        </div>
        <div :class="
        $vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12'
          : 'col-xl-6 col-lg-6 mb-2'
      ">
        <div class="card shadow">
          <div class="
              card-header
              py-3
              d-flex
              align-items-center
              justify-content-between
            ">
            <h6 class="m-0 font-weight-bold text-primary">
              Escolas com estudantes com deficiência, transtorno do espectro autista, altas habilidades
            </h6>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>
                  Escolas com estudantes com deficiência, transtorno do espectro autista, altas habilidades
              </span>
            </v-tooltip>
          </div>
          <div id="chart2">
            <v-data-table 
            height="305"  
            :items="unidadesAlunosEspeciaisList" 
            :headers="headersUnidadesAlunosEspeciais" 
            :items-per-page="5"
            :loading="loadingUnidadesAlunosEspeciais"
            class="elevation-1 custom-data-table"
            >
            </v-data-table>
          </div>
        </div>
      </div>
    </v-row>
      <v-row>
        <div :class="
        $vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12 mb-2'
          : 'col-xl-6 col-lg-6 mb-2'
      ">
        <div class="card shadow mb-3">
          <div class="
              card-header
              py-3
              d-flex
              align-items-center
              justify-content-between
            ">
            <h6 class="m-0 font-weight-bold text-primary">
              Resumo dos(as) estudantes com outra condição de necessidade específica
            </h6>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span> Estudantes com outra condição de necessidade específica </span>
            </v-tooltip>
          </div>
          <div v-if="!loadingUnidadesAlunosEspeciaisChart" id="chart1">
            <apexchart type="bar" height="350" :options="chartOptionsAlunosEspecifica" :series="seriesAlunosEspecifica" :key="showAlunosDeficientesEspecifica"></apexchart>
          </div>
          <div v-else style="min-height: 400px;">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            Carregando dados...
          </div>
        </div>
      </div>
        <div :class="
          $vuetify.breakpoint.mdAndDown
            ? 'col-xl-12 col-md-12'
            : 'col-xl-6 col-lg-6 mb-2'
        ">
          <div class="card shadow">
            <div class="
                card-header
                py-3
                d-flex
                align-items-center
                justify-content-between
              ">
              <h6 class="m-0 font-weight-bold text-primary">
                Escolas com estudantes com outra condição de necessidade específica
              </h6>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                    Escolas com estudantes com outra condição de necessidade específica
                </span>
              </v-tooltip>
            </div>
            <div id="chart2">
              <v-data-table 
              height="305"  
              :items="unidadesAlunosOutrosList" 
              :headers="headersUnidadesAlunosEspeciais" 
              :items-per-page="5"
              :loading="loadingUnidadesAlunosEspeciais"
              class="elevation-1 custom-data-table"
              >
              </v-data-table>
            </div>
          </div>
        </div>
      </v-row>
      <v-row>
        <div :class="
          $vuetify.breakpoint.mdAndDown
            ? 'col-xl-12 col-md-12 mb-2'
            : 'col-xl-6 col-lg-6 mb-2'
        ">
          <div class="card shadow mb-3">
            <div class="
                card-header
                py-3
                d-flex
                align-items-center
                justify-content-between
              ">
              <h6 class="m-0 font-weight-bold text-primary">
                Resumo dos estudantes que necessitam de recursos de sala de aula
              </h6>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span> Resumo dos estudantes que necessitam de recursos de sala de aula</span>
              </v-tooltip>
            </div>
            <div v-if="!loadingUnidadesAlunosEspeciaisChart" id="chart1">
              <apexchart type="bar" height="350" :options="alunosRecursosSalaAula" :series="seriesAlunosRecursosSalaAula" :key="showAlunosRecursosSalaAula"></apexchart>
            </div>
            <div v-else style="min-height: 400px;">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
              Carregando dados...
            </div>
          </div>
        </div>
        <div :class="
        $vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12'
          : 'col-xl-6 col-lg-6 mb-2'
      ">
        <div class="card shadow">
          <div class="
              card-header
              py-3
              d-flex
              align-items-center
              justify-content-between
            ">
            <h6 class="m-0 font-weight-bold text-primary">
              Escolas com estudantes que necessitam de professor auxiliar
            </h6>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>
                Escolas com estudantes que necessitam de professor auxiliar
              </span>
            </v-tooltip>
          </div>
            <v-data-table 
            height="305"  
            :items="unidadesProfessorAuxiliar" 
            :loading="loadingProfessorAux"
            :headers="headersUnidadesAlunosEspeciais" 
            :items-per-page="5"
            class="elevation-1 custom-data-table"
            >
            </v-data-table>
        </div>
      </div>
    </v-row>
      <v-row>
        <div :class="
          $vuetify.breakpoint.mdAndDown
            ? 'col-xl-12 col-md-12 mb-2'
            : 'col-xl-6 col-lg-6 mb-2'
        ">
          <div class="card shadow mb-3">
            <div class="
                card-header
                py-3
                d-flex
                align-items-center
                justify-content-between
              ">
              <h6 class="m-0 font-weight-bold text-primary">
                Comparativo anual de matrículas
              </h6>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  Veja o comparativo anual de matriculas dos últimos anos
                </span>
              </v-tooltip>
            </div>
            <div class="card-body py-3 align-items-center justify-content-between">
              <quantitativoMatriculados></quantitativoMatriculados>
            </div>
          </div>
        </div>

        <div :class="
          $vuetify.breakpoint.mdAndDown
            ? 'col-xl-12 col-md-12 mb-2'
            : 'col-xl-6 col-lg-6 mb-2'
        ">
          <div class="card shadow mb-3">
            <div class="
                card-header
                py-3
                d-flex
                align-items-center
                justify-content-between
              ">
              <h6 class="m-0 font-weight-bold text-primary">
                Quantitativo por situação acadêmica
              </h6>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  Veja o quantitativo de estudantes por situação acadêmica, após a
                  finalização do ano letivo
                </span>
              </v-tooltip>
            </div>
            <div class="card-body align-items-center justify-content-between">
              <quantitativo :idAno="idAno" :key="quantitativo"></quantitativo>
            </div>
          </div>
        </div>
      </v-row>

      <v-row>
        <div :class="
          $vuetify.breakpoint.mdAndDown
            ? 'col-xl-12 col-md-12 mb-2'
            : 'col-xl-6 col-lg-6 mb-2'
        ">
          <div class="card shadow mb-4">
            <div class="
                card-header
                py-0
                d-flex
                flex-row
                align-items-center
                justify-content-between
              ">
              <h6 class="m-0 font-weight-bold text-primary">Zona urbana</h6>

              <div class="col-xl-6 col-lg-6 d-flex">
                <filtroEtapa class="mr-5" @messageFromChild="setEtapaUrbana"></filtroEtapa>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>
                    Veja o quantitativo de alunos masculino e feminino na zona urbana
                  </span>
                </v-tooltip>
              </div>
            </div>
            <div class="card-body">
              <graficoUrbana :idEtapa="idEtapaUrbana" :idAno="idAno" :idZona="idZonaUrbana" :key="etapaUrbana">
              </graficoUrbana>
            </div>
          </div>
        </div>

        <div :class="
          $vuetify.breakpoint.mdAndDown
            ? 'col-xl-12 col-md-12 mb-2'
            : 'col-xl-6 col-lg-6 mb-2'
        ">
          <div class="card shadow mb-4">
            <div class="
                card-header
                py-0
                d-flex
                flex-row
                align-items-center
                justify-content-between
              ">
              <h6 class="m-0 font-weight-bold text-primary">Zona rural</h6>

              <div class="col-xl-6 col-lg-6 d-flex">
                <filtroEtapa class="mr-5" @messageFromChild="setEtapaRural"></filtroEtapa>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>
                    Veja o quantitativo de alunos masculino e feminino na zona rural
                  </span>
                </v-tooltip>
              </div>
            </div>
            <div class="card-body">
              <graficoRural :idEtapa="idEtapaRural" :idAno="idAno" :idZona="idZonaRural" :key="etapaRural">
              </graficoRural>
            </div>
          </div>
        </div>
      </v-row>
      <v-row>
          
        <div class="col-xl-12 col-md-12 mb-2">
            <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Quantidade de docentes por segmento/turno</h6>
              </div>
              <div class="card-body">
                  <v-text-field
                      class="ml-3 mr-3"
                      v-model="searchProfessoresTurno"
                      prepend-icon="mdi-feature-search-outline"
                      label="Pesquisar"
                      clearable
                  ></v-text-field>
                  <v-data-table
                      :search="searchProfessoresTurno"
                      :headers="headersProfessoresTurno"
                      :items="professoresTurnoList"     
                      :loading="loadingProfessoresTurno"                 
                      hide-default-footer
                      class="elevation-1 custom-data-table"
                  ></v-data-table>
              </div>
            </div>
          </div>


      </v-row>
      
    </div>
  </div>
</template>

<script>
import CardIndicadores from "@/components/educacao/CardIndicadores.vue";
import CardPieEducacao from "@/components/educacao/CardPieEducacao.vue";
import quantitativoMatriculados from "@/components/educacao/EducacaoQuantitativoAnualMatriculados.vue";
import filtroAno from "@/components/educacao/EducacaoFiltroAno.vue";
import filtroEtapa from "@/components/educacao/EducacaoFiltroEtapa.vue";
import graficoUrbana from "@/components/educacao/EducacaoQuantitativoEtapaUrbana.vue";
import graficoRural from "@/components/educacao/EducacaoQuantitativoEtapaRural.vue";
import Calendario from "@/components/educacao/EducacaoCalendarioBimestral.vue";
import quantitativo from "@/components/educacao/EducacaoQuantitativoAcademico.vue";

export default {
  components: {
    CardIndicadores,
    CardPieEducacao,
    quantitativoMatriculados,
    filtroEtapa,
    filtroAno,
    graficoUrbana,
    graficoRural,
    Calendario,
    quantitativo,
  },
  data() {
    return {
      quantitativo: null,
      idEtapaRural: null,
      idEtapaUrbana: null,
      calendario: null,
      idZonaUrbana: 9,
      idZonaRural: 10,
      etapaRural: null,
      etapaUrbana: null,
      bool: false,
      qtdTurmas: null,
      qtdAlunos: null,
      qtdProfessores: null,
      qtdDiretores: null,
      objUrbano: [],
      objRural: [],
      escolas: [],
      qtdEscolas: null,
      infoCards: [],
      showPie: null,
      show: false,
      matriculas: [],
      matriculasAgrupamento: [],
      dadosGrafico: [],
      datas: [],
      idAno: new Date().getFullYear(),
      idEtapa: null,
      loadingProfessorAux: false,
      loadingProfessorAuxChart: false,
      loadingEscolas: false,
      loadingDeficiencia: false,
      loadingTransporteEscolar: false,
      loadingTransporteEscolarChart: false,
      loadingProfessoresTurno: false,
      loadingUnidadesAlunosEspeciais: false,
      loadingUnidadesAlunosEspeciaisChart: false,
      showAlunosRecursosSalaAula: null,
      showTransporteEscolar: null,
      showAlunosDeficientes: null,
      showAlunosDeficientesEspecifica: null,
      unidadesAlunosEspeciaisList: [],
      unidadesAlunosOutrosList: [],
      unidadesProfessorAuxiliar: [],
      unidadesTransporteEscolarList: [],
      searchProfessoresTurno: [],
      professoresTurnoList: [],
      headersUnidadesAlunosEspeciais:[
            {text:'Nome da unidade', value:'Unidade', width:"60%", align:"left"},
            {text:'Quantidade', value:'qtd', width:"40%", align:"right"},
        ],
      headersProfessoresTurno:[
            {text:'Segmento', value:'Segmento', width:"40%", align:"left"},
            {text:'Turno', value:'Turno', width:"40%", align:"left"},
            {text:'Quantidade', value:'qtd', width:"10%", align:"right"}
        ],


      seriesAlunos: [
        {
          name: "Quantidade",
          data:[]}
      ],
      seriesTransportes: [
        {
          name: "Quantidade",
          data:[]}
      ],
      seriesAlunosRecursosSalaAula: [
        {
          name: "Quantidade",
          data:[]}
      ],

      chartOptionsAlunos: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        }
      },

      seriesAlunosEspecifica: [
        {
          name: "Quantidade",
          data:[]}
      ],

      chartOptionsAlunosEspecifica: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        }
      },
      alunosRecursosSalaAula: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        }
      },
      chartOptionsTransporteEscolar: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        }
      },
    };
  },
  methods: {
    getEscolas() {
      this.loadingEscolas = true;
      this.axios
        .get("escola/escolas/all", {
          params: {
            ano: this.idAno,
          },
        })
        .then((res) => {
          this.qtdEscolas = res.data.length;
          this.escolas = res.data;
          this.showPie++;
          this.loadingEscolas = false;
        });
    },
    getQtdAlunosDeficiencia(censo) {
      this.loadingDeficiencia = true;
      this.axios
        .get("escola/escolas/alunosComNecessidadesEspeciais", {
          params: {
            ano: this.idAno,
            censo: censo,
          },
        })
        .then((res) => {
          if (censo == 1) {
            for(var i=0; i < res.data.length; i++){
            this.seriesAlunos[0].data.push(res.data[i].qtd)
            this.chartOptionsAlunos.xaxis.categories.push(res.data[i].Deficiencia)
          }
          this.showAlunosDeficientes++
          } else{
            for(var j=0; j < res.data.length; j++){
            this.seriesAlunosEspecifica[0].data.push(res.data[j].qtd)
            this.chartOptionsAlunosEspecifica.xaxis.categories.push(res.data[j].Deficiencia)
          }
          this.showAlunosDeficientesEspecifica++
          }
          this.loadingDeficiencia = false;
        });
    },
    getQtdTransporteEscolar() {
      this.loadingTransporteEscolarChart = true
      this.axios
        .get("escola/escolas/transporteEscolar", {
          params: {
            anoLetivo: this.idAno,
          },
        })
        .then((res) => {
            for(var i=0; i < res.data.length; i++){
            this.seriesTransportes[0].data.push(res.data[i].qtd)
            this.chartOptionsTransporteEscolar.xaxis.categories.push(res.data[i].Transporte)
          }
          this.showTransporteEscolar++
          this.loadingTransporteEscolarChart = false
        });
    },
    getQtdAlunosRecursosSalaAula() {
      this.loadingUnidadesAlunosEspeciaisChart = true
      this.axios
        .get("escola/escolas/alunosRecursosSalaAula", {
          params: {
            anoLetivo: this.idAno,
          },
        })
        .then((res) => {
            for(var i=0; i < res.data.length; i++){
            this.seriesAlunosRecursosSalaAula[0].data.push(res.data[i].qtd)
            this.alunosRecursosSalaAula.xaxis.categories.push(res.data[i].Recurso)
          }
          this.showAlunosRecursosSalaAula++
          this.loadingUnidadesAlunosEspeciaisChart = false
        });
    },
    getQtdUnidadesAlunosDeficiencia(censo) {
      this.loadingUnidadesAlunosEspeciais = true;
      this.axios
        .get("escola/escolas/unidadesComAlunosEspeciais", {
          params: {
            ano: this.idAno,
            censo: censo,
          },
        })
        .then((res) => {
          if (censo == 1){this.unidadesAlunosEspeciaisList = res.data}
          else{this.unidadesAlunosOutrosList = res.data}
          this.loadingUnidadesAlunosEspeciais = false;
        });
    },
    getQtdUnidadesTransporteEscolar() {
      this.loadingTransporteEscolar = true;
      this.axios
        .get("escola/escolas/transporteEscolarPorUnidade", {
          params: {
            anoLetivo: this.idAno,
          },
        })
        .then((res) => {
          this.unidadesTransporteEscolarList = res.data
          this.loadingTransporteEscolar = false;
        });
      
    },
    getQtdUnidadesProfessorAuxiliar() {
      this.loadingProfessorAux = true;
      this.axios
        .get("escola/escolas/escolasComProfessorAuxiliar", {
          params: {
            anoLetivo: this.idAno,
          },
        })
        .then((res) => {
          this.unidadesProfessorAuxiliar = res.data
          this.loadingProfessorAux = false;
        });
    },
    async getMatriculas() {
      await this.axios.get("escola/escolas/matriculas").then((res) => {
        this.matriculas = res.data;
      });
    },
    async getMatriculasAgrupamento() {
      await this.axios
        .get("escola/escolas/matriculas-agrupamento", {
          params: {
            ano: this.idAno,
          },
        })
        .then((res) => {
          this.matriculasAgrupamento = res.data;
        });
    },
    getAlunosTurno() {
      this.axios.get("turma/alunosturno").then((res) => {
        for (var i = 0; i < 4; i++) {
          if (res.data[i]) {
            let data = res.data[i];
            this.infoCards.push({
              color: "warning",
              icon: "users",
              label: data.Turno,
              data: data.alunosMatriculados,
            });
          }
        }
      });
    },
    getAlunosQtd() {
      this.axios
        .get("turma/turmas", {
          params: {
            ano: this.idAno,
          },
        })
        .then((res) => {
          this.qtdAlunos = res.data[0].alunosMatriculados;
          this.qtdTurmas = res.data[1].turmas;
        });
    },
    getProfessores() {
      this.axios
        .get("escola/escolas/professores", {
          params: {
            AnoLetivo: this.idAno,
          },
        })
        .then((res) => {
          this.qtdProfessores = res.data.length;
        });
    },
    getProfessorTurno(){
      this.loadingProfessoresTurno = true;
        this.axios.get('escola/escolas/professoresturno', { 
            params: {
            ano: this.idAno
            }
        }).then(res => {
            this.professoresTurnoList = res.data
            this.loadingProfessoresTurno = false;
        })
      },
    getDiretores() {
      this.axios.get("escola/escolas/diretores").then((res) => {
        this.qtdDiretores = res.data.length;
      });
    },
    groupBy(array, key) {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
      }, {});
    },
    setEtapaRural(value) {
      this.idEtapaRural = value;
      this.etapaRural++;
    },
    setEtapaUrbana(value) {
      this.idEtapaUrbana = value;
      this.etapaUrbana++;
    },
    recall(value) {
      this.idAno = value;
      this.etapaRural++;
      this.etapaUrbana++;
      this.calendario++;
      this.quantitativo++;

      this.IdEscola = parseInt(sessionStorage.getItem("unidade"));
      this.getAlunosQtd();
      this.getEscolas();
      this.getProfessores();
      //this.getDiretores();
      this.getQtdAlunosDeficiencia();
      this.getQtdUnidadesAlunosDeficiencia(1);
      this.getQtdUnidadesAlunosDeficiencia(0);
    },
  },

  mounted() {
    this.IdEscola = parseInt(sessionStorage.getItem("unidade"));
    this.getProfessorTurno()
    this.getAlunosQtd();
    this.getEscolas();
    this.getProfessores();
    //this.getDiretores();
    this.getQtdAlunosDeficiencia(0);
    this.getQtdAlunosDeficiencia(1);
    this.getQtdTransporteEscolar();
    this.getQtdAlunosRecursosSalaAula();
    this.getQtdUnidadesTransporteEscolar();
    this.getQtdUnidadesProfessorAuxiliar();
    this.getQtdUnidadesAlunosDeficiencia(1);
    this.getQtdUnidadesAlunosDeficiencia(0);
    this.getAlunosUrbana();
    this.getAlunosRural();
  },
};
</script>
<style scoped>
::v-deep .custom-data-table thead th {
  color: white !important;
  background-color: #2fb1e5 !important;
  font-size: 14px !important;
}
</style>
<style>
@media screen and (min-width: 1264px) and (max-width: 7680px) {
  #Calendario {
    height: 31rem;
    width: 29rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -13rem;
    margin-top: auto;
  }

  #Pizza {
    height: 5rem;
    width: 24rem !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 13rem;
    margin-top: auto;
  }
}

@media screen and (min-width: 481px) and (max-width: 1263px) {
  #Pizza {
    height: 20rem;
    width: 35rem !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7rem;
    margin-top: auto;
  }
}
</style>