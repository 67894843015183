<template>
    <div class="page">
        <br />
        <div class="container-fluid">
            <v-row class="mb-2">
                <v-col cols="12">
                    <v-card shaped elevation="1">
                        <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                           Ministerio da saúde
                        </h4>
                        <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center"> Log de integrações </h5>
                    </v-card>
                </v-col>
            </v-row>
            <v-card>
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Dispensações</h6>
                </div>
            <v-row class="m-2">
                <v-col align-self="center">
                    <v-select
                    v-model="situacao"
                    :items="situacaoItems"
                    label="Situação"
                    clearable
                    required
                    @change="refreshSituacao"
                    ></v-select>
                </v-col>
                    <DataRange
                    :dateStart="filters.datainicial"
                    :dateFinish="filters.datafinal" 
                    @date:start="refreshStart"
                    @date:finish="refreshFinish"
                    />
            </v-row>
            <v-data-table
                v-model="expanded"
                :headers="headers"
                :items="dados"
                :items-per-page="10"
                :search="search"
                item-value="name"
                show-expand
                item-key="created_at"
                class="elevation-1 custom-data-table"
                :loading="loading"
                loading-text="Carregando..."
                :server-items-length="qntRegistros"
                @update:page="updatePagination"
                @update:items-per-page="updateRowsPagination"
                >
                <template v-slot:[`item.created_at`]="{ item }">
                    {{ $moment(item.created_at).format('L LTS') }}
                </template>

                <template v-slot:[`item.statusCode`]="{ item }">
                    <v-chip v-if="item.statusCode == 200" color="success" dark> Aceito </v-chip>
                    <v-chip v-else> Rejeitado </v-chip>
                </template>

                <template v-slot:[`item.cnes`]="{ item }">
                    <v-chip v-if="item.rotaOrigem == 'EntradaMateriais'"> {{ item.requisicao.estabelecimento.cnes }} </v-chip>
                    <v-chip v-if="item.rotaOrigem != 'EntradaMateriais'"> {{ item.requisicao.estabelecimentoDispensador.cnes }} </v-chip>
                </template>

                <template v-slot:[`item.dataMovimentacao`]="{ item }">
                    <v-chip v-if="item.rotaOrigem == 'EntradaMateriais'"> {{ $moment(item.requisicao.caracterizacao.dataEntrada).format('L') }} </v-chip>
                    <v-chip v-if="item.rotaOrigem != 'EntradaMateriais'"> {{ $moment(item.requisicao.caracterizacao.dataDispensacao).format('L') }} </v-chip>
                </template>

                <template v-slot:[`item.numeroDocumento`]="{ item }">
                    <v-chip v-if="item.rotaOrigem == 'EntradaMateriais'"> {{ item.requisicao.caracterizacao.numeroDocumento  }} </v-chip>
                    <v-chip v-if="item.rotaOrigem != 'EntradaMateriais'"> {{ item.requisicao.usuarioSus.cns }} </v-chip>
                </template>

                <template v-slot:expanded-item="{ item }">
                    <td colspan="12" style="padding: 10px">
                        <v-data-table
                            :headers="headersDispensacaoSIS"
                            :items="item.requisicao.itens"
                            :loading="loading"
                            loading-text="Carregando..."
                            class="elevation-0 custom-data-table"
                            hide-default-footer
                            disable-pagination
                        >
                        <template v-slot:[`item.dataValidade`]="{ item }">
                            {{ $moment(item.dataValidade).format('L') }}
                        </template>
                        </v-data-table>
                    </td>
                </template>
            </v-data-table>
            </v-card>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import DataRange from '@/components/DataRange'

export default {
    components: {
      DataRange
    },
    data () {
        return {
            situacaoItems: [
                { text: 'Aceitos', value: 1 },
                { text: 'Rejeitados', value: 2 }
            ],

            menu1: false,
            menu2: false,
            anos: [],
            dados: [],
            requisicoesItens: [],
            expanded: [],
            IdAno: 2019,
            loading: false,
            search: null,
            qntRegistros: 0,
            filters: {
                page: 1,
                per_page: 10,
                situacao: null,
                datainicial: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                datafinal: moment().format('YYYY-MM-DD'),
            },
            headers: [
                { text: "", value: "data-table-expand" },
                { text:'Situação', value:'statusCode', width:'10%' },
                { text:'Código do registro', value:'codigoRegistro', width:'10%' },
                { text:'Data da integração', value:'created_at', width:'20%' },
                { text:'CNES', value:'cnes', width:'10%' },
                { text:'Origem dos dados', value:'rotaOrigem', width:'20%' },
                { text:'Informações retornadas', value:'exceptions', width:'20%' },
                { text:'Requisição', value:'IdRequisicao', width:'10%' },
                { text:'Data da movimentação', value:'dataMovimentacao', width:'10%' },
                { text:'CNS / NF', value:'numeroDocumento', width:'10%' }
            ],
            headersDispensacaoSIS: [
                { text: 'CATMAT', value: 'numero', width: '20%' },
                { text: 'Quantidade', value: 'quantidade', width: '20%' },
                { text: 'Lote', value: 'lote', width: '20%' },
                { text: 'Validade', value: 'dataValidade', width: '20%' },
                { text: 'Fabricante', value: 'cnpjFabricante', width: '20%' }
            ]
        }
    },
    methods: {
        updatePagination(page){
          this.filters.page = page
          this.getDispensacoes()
        },
        updateRowsPagination(per_page){
          this.filters.per_page = per_page
          this.getDispensacoes()
        },
        refreshStart(val) {
            this.filters.datainicial = val,
            this.getDispensacoes()
        },
        refreshFinish(val) {
            this.filters.datafinal = val,
            this.getDispensacoes()
        },
        refreshSituacao(situacao){
            this.filters.situacao = situacao,
            this.getDispensacoes()
        },
        getDispensacoes() {
            this.loading = true;
            this.axios.get(`/qualifar/dispensacao`, {params: this.filters})
            .then((res) => {
                this.dados = res.data.data;
                this.qntRegistros = res.data.total
            }).finally(() => {
                this.loading = false;
            });
        },
        limparFiltros() {
            this.situacao = null;
            this.datainicial = null;
            this.datafinal = null;
            this.getDispensacoes();
        }
    },
    mounted() {
        this.getDispensacoes();
    }
}
</script>
<style scoped>
::v-deep .custom-data-table thead th {
  color: white !important;
  background-color: #2fb1e5 !important;
  font-size: 14px !important;
}
</style>