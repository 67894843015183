<template>
    <div class="page">
        <progress-system :isLoad="progress"></progress-system>
        <div class="container-fluid">
            <br>
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Meus exames</h1>
            </div>

            <v-row>
                <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                    <v-text-field
                        dense
                        outlined
                        clearable
                        hide-details
                        append-icon="mdi-magnify"
                        label="Nome da observação"
                        v-model="search"
                    ></v-text-field>    
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                    <v-select
                        hide-details
                        outlined
                        dense
                        clearable
                        v-model="searchAno"
                        :items="datas"
                        label="Filtrar pelo ano"
                        item-value="idAno"
                        item-text="ano"
                    >
                    </v-select>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="listexames"
                :items-per-page="10"
                :search="(search && search.toString()) || (searchAno && searchAno.toString())"
                class="elevation-1 custom-data-table"
            >
            <template v-slot:[`item.DataIns`]="{ item }">
                <span> {{$moment(item.DataIns).format('L')}}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn :href="item.Path" color="success" target="_blank"> Visualizar
                    <v-icon  target="_blank">mdi mdi-file</v-icon>
                </v-btn>
            </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>

import ProgressSystem from '../../components/ProgressSystem'

export default {
    name:'Exames',
    components:{
        ProgressSystem
    },
    data: () => ({
        dynamicCol: '',
        progress:true,
        listexames:[],
        headers:[
            {text:'Observação',value:'Obs'},
            {text:'Data',value:'DataIns'},
            {text:'Exame',value:'actions', sortable: false},
         ],
        datas:[],
        search: null,
        searchAno: null,
        idAno: null,
    }),
    methods:{
        getExames(){
            this.axios.get('exames', {
        }).then(res =>{
                this.listexames = res.data;
            }).catch( e => {
                console.log(e.response);
            }).finally(()=>{
                this.progress = false;
            })
        },
        clearFilters(){
        this.Datafilter = null
        this.search = null
        this.getExames()
        },
        gerarAnos() {
            var max = new Date().getFullYear()
            var min = max - 19
            var anos = []

            for (var i = max; i >= min; i--) {
                anos.push({
                    idAno: i,
                    ano: i.toString()
                })
            }
            this.datas = anos
        },
    },
    mounted(){
        this.getExames();
        this.gerarAnos();
    }

    
}
</script>
<style scoped>
::v-deep .custom-data-table thead th {
  color: white !important;
  background-color: #2fb1e5 !important;
  font-size: 14px !important;
}
</style>