<template>
  <div class="page">
    <br />
    <div class="container-fluid">
        <div class="col-xl-12 col-lg-12">
                <h1 class="h3 mb-0 text-gray-800">Relatório de indicadores da SISAB</h1>
        </div>

        <v-row text="Dados de indicadores do quadrimestre: 2023 Q1">
        <div class="col-xl-12 col-lg-12">
                <h1 class="h4 mb-0 text-gray-800">Dados de indicadores do primeiro quadrimestre</h1>
        </div>     
            <div>
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">Dados de indicadores do quadrimestre: 2023 Q1</h6>
                </div>
                <v-data-table
                    :headers="headersdashboard1"
                    :items="graficoDados1"
                    class="elevation-1 custom-data-table"
                    style="width: 100%">   
                </v-data-table>
            </div>
        </v-row>

        <v-row text="Dados de indicadores do quadrimestre: 2023 Q2"> 
        <div class="col-xl-12 col-lg-12">
                <h1 class="h4 mb-0 text-gray-800">Dados de indicadores do segundo quadrimestre</h1>
        </div>      
            <div>
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">Dados de indicadores do quadrimestre: 2023 Q2</h6>
                </div>
                <v-data-table
                    :headers="headersdashboard2"
                    :items="graficoDados2"
                    class="elevation-1 custom-data-table"
                    style="width: 100%">    
                </v-data-table>
            </div>
        </v-row>

        <!-- <v-row text="Dados de indicadores do quadrimestre: 2022 Q3"> 
        <div class="col-xl-12 col-lg-12">
                <h1 class="h4 mb-0 text-gray-800">Dados de indicadores do terceiro quadrimestre</h1>
        </div>      
            <div>
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">Dados de indicadores do quadrimestre: 2022 Q3</h6>
                </div>
                <v-data-table
                    :headers="headersdashboard3"
                    :items="graficoDados3"
                    class="elevation-1"
                    style="width: 100%">  
                </v-data-table>
            </div>
        </v-row> -->
      </div> 

       

  </div>
</template>

<script>
export default {
  name: "SaudeIndicador",
  data: () => {
    return {
      graficoDados1:[
        {
          // uf:'RN',
          // ibge: '240710',
          // municipio: 'Macaíba',
          // gestantesPre: '33%',
          // gestantesHiv: '73%',
          // gestantesBucal: '68%',
          // coberturaCito: '17%	',
          // coberturaPolio: '50%',
          // hipertensao: '16%',
          // diabetes: '12%',
        }
      ],
      graficoDados2:[
        {
          // 'uf':'RN',
          // 'ibge': '240710',
          // 'municipio': 'Macaíba',
          // 'gestantesPre': '31%',
          // 'gestantesHiv': '76%',
          // 'gestantesBucal': '64%',
          // 'coberturaCito': '19%	',
          // 'coberturaPolio': '59%	',
          // 'hipertensao': '13%	',
          // 'diabetes': '10%',
        }
      ],
      graficoDados3:[
        {
          'uf':'RN',
          'ibge': '240710',
          'municipio': 'Macaíba',
          'gestantesPre': '25%',
          'gestantesHiv': '36%',
          'gestantesBucal': '45%',
          'coberturaCito': '15%	',
          'coberturaPolio': '20%	',
          'hipertensao': '3%	',
          'diabetes': '17%',
        }
      ],
    headersdashboard1: [
      {text: 'UF', value: 'uf', show: true, width: '5%'},
      {text: 'IBGE', value: 'ibge', show: true, width: '5%'},
      {text: 'Municipio', value: 'municipio', show: true, width: '10%'},
      {text: 'Pré-Natal(6 consultas)', value: 'gestantesPre', show: true, width: '10%'},
      {text: 'Pré-Natal(sífilis e hiv)', value: 'gestantesHiv', show: true, width: '10%'},
      {text: 'Gestantes saúde bucal', value: 'gestantesBucal', show: true, width: '10%'},
      {text: 'Cobertura citopatológico', value: 'coberturaCito', show: true, width: '10%'},
      {text: 'Cobertura Pólio e Penta', value: 'coberturaPolio', show: true, width: '10%'},
      {text: 'Hipertensão (PA aferida)', value: 'hipertensao', show: true, width: '10%'},
      {text: 'Diabetes (hemoglobina glicada)', value: 'diabetes', show: true, width: '10%'}
    ],
    headersdashboard2: [
      {text: 'UF', value: 'uf', show: true, width: '5%'},
      {text: 'IBGE', value: 'ibge', show: true, width: '5%'},
      {text: 'Municipio', value: 'municipio', show: true, width: '10%'},
      {text: 'Pré-Natal(6 consultas)', value: 'gestantesPre', show: true, width: '10%'},
      {text: 'Pré-Natal(sífilis e hiv)', value: 'gestantesHiv', show: true, width: '10%'},
      {text: 'Gestantes saúde bucal', value: 'gestantesBucal', show: true, width: '10%'},
      {text: 'Cobertura citopatológico', value: 'coberturaCito', show: true, width: '10%'},
      {text: 'Cobertura Pólio e Penta', value: 'coberturaPolio', show: true, width: '10%'},
      {text: 'Hipertensão (PA aferida)', value: 'hipertensao', show: true, width: '10%'},
      {text: 'Diabetes (hemoglobina glicada)', value: 'diabetes', show: true, width: '10%'}
    ],
    headersdashboard3: [
      {text: 'UF', value: 'uf', show: true, width: '5%'},
      {text: 'IBGE', value: 'ibge', show: true, width: '5%'},
      {text: 'Municipio', value: 'municipio', show: true, width: '10%'},
      {text: 'Pré-Natal(6 consultas)', value: 'gestantesPre', show: true, width: '10%'},
      {text: 'Pré-Natal(sífilis e hiv)', value: 'gestantesHiv', show: true, width: '10%'},
      {text: 'Gestantes saúde bucal', value: 'gestantesBucal', show: true, width: '10%'},
      {text: 'Cobertura citopatológico', value: 'coberturaCito', show: true, width: '10%'},
      {text: 'Cobertura Pólio e Penta', value: 'coberturaPolio', show: true, width: '10%'},
      {text: 'Hipertensão (PA aferida)', value: 'hipertensao', show: true, width: '10%'},
      {text: 'Diabetes (hemoglobina glicada)', value: 'diabetes', show: true, width: '10%'}
    ],
      
    }
  },
}
</script>
<style scoped>
::v-deep .custom-data-table thead th {
  color: white !important;
  background-color: #2fb1e5 !important;
  font-size: 14px !important;
}
</style>