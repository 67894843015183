<template>
    <div class="container-fluid">
        <v-row>
                <v-col cols="6">

                    <audio ref="audio">
                        <source src="@/static/bell-song.mp3" type="audio/mp3">
                    </audio>
                    <v-card height='500PX'>
                            <v-layout column>

                                <h4 v-if="NomePaciente" class="primary--text text-center font-weight-bold mt-10" style="font-size:100px">
                                    {{ NomePaciente }}
                                    {{ Senha }}
                                </h4>
                                <h4 v-else class="primary--text text-center font-weight-bold mt-5" style="font-size:200px">
                                    {{ Senha }}
                                </h4>
                                <h4 class="--text text-center font-weight-bold" style="font-size:90px; color:red;">
                                    {{ Local }}
                                </h4>
                            </v-layout>

                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card  height='500px' max-height="500px">
                        <iframe id="Video" width="100%" height="100%" src="https://www.youtube.com/embed/videoseries?list=PL12jx8elnGwbw8O-gUkqfvQuTc6UD3cvm&autoplay=1&mute=1&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen muted></iframe>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card  height='430' style="overflow-y:auto">
                        <h4 class="primary--text font-weight-bold pt-5" style="text-align: center !important">
                            ÚLTIMAS SENHAS CHAMADAS
                        </h4>
                        <v-data-table
                        :headers="headersSenhasChamadas"
                        class="elevation-1 custom-data-table"
                        :items="senhasChamadasList"
                        itemsPerRow="6"
                        >
                            <template v-slot:[`item.Senha`]=" { item } ">
                                <span class="primary--text" style="font-size: 2rem"> {{item.Senha}} </span>
                            </template>

                            <template v-slot:[`item.NomePaciente`]=" { item } ">
                                <span class="primary--text" style="font-size: 2rem"> {{item.NomePaciente}} </span>
                            </template>

                            <template v-slot:[`item.TipoAtendimento`]=" { item } ">
                                <span class="primary--text" style="font-size: 2rem"> {{item.TipoAtendimento}} </span>
                            </template>

                            <template v-slot:[`item.strLocal`]=" { item } ">
                                <span class="primary--text" style="font-size: 1.5rem"> {{item.strLocal}} </span>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>

            </v-row>
    </div>

</template>

<script>

import { delay } from '@/utils/delay.js'

export default {
    components: {
	},
    watch: {
        init: {
            immediate: true,
            handler() {
                this.getUltimaFicha()
                this.getSenhasChamadas()
                //this.getVideos()
            }
        }
    },
    data() {
        return {
            senhasChamadasList:[],
            plantonistasList:[],
            Visualizado: null,
            NomePaciente: null,
            Local: null,
            Classificacao: null,
            Senha: null,
            idUnidade: this.$route.query.IdUnidade,
            idPainel: this.$route.query.IdPainel,

            headersSenhasChamadas:[
                    {text:'Número da senha', value:'Senha', width:"25%", align:"center"},
                    {text:'Nome', value:'NomePaciente', width:"25%", align:"center"},
                    {text:'Tipo do atendimento', value:'TipoAtendimento', width:"25%", align:"center"},
                    {text:'Local', value:'strLocal', width:"25%", align:"center"}
                ],
            reload: null,
            index: 0,
            videoArray: [],
        }
    },
    methods: {
        falarNome(nome){
            let msg = new SpeechSynthesisUtterance()
            msg.text = nome
            window.speechSynthesis.speak(msg)
        },
        zerarCampos(){
            this.Senha = null
            this.Local = null
            this.NomePaciente = null

        },
        async getSenhasChamadas(){
            await this.axios.get('painelAssistencia/ultimasenhachamada', { params: { IdUnidade: this.idUnidade } })
            .then(res => {
                this.senhasChamadasList = res.data
                setTimeout(this.getSenhasChamadas, 20000);
            })
        },
        async getUltimaFicha(){
            await this.axios.get('painelAssistencia/senhaschamadas', { params: { IdUnidade: this.idUnidade, IdPainel: this.idPainel } })
            .then(res => {
                this.zerarCampos();
                this.Local = res.data.strLocal
                this.Senha = res.data.Senha
                this.NomePaciente = res.data.NomePaciente

                if (res.data.Visualizado < 2) {
                    this.$refs.audio.play()
                    delay(1000);
                    this.NomePaciente ? this.falarNome(this.NomePaciente) : this.falarNome('Senha ' + this.Senha)
                    this.falarNome(this.Local)
                }
                setTimeout(this.getUltimaFicha, 20000);
            })

		},
        nextVideo(){
            if(this.index >= this.videoArray.length - 1){
                this.index = 0
                this.reload++
            }
            else{
                this.index++
                this.reload++
            }
        },
    },
    mounted() {
        setInterval(() => {
            location.reload();
        }, 1800000); // timer de 30 minutos(para uma hora 3600000)
    },
}

</script>
<style scoped>
::v-deep .custom-data-table thead th {
  color: white !important;
  background-color: #2fb1e5 !important;
  font-size: 14px !important;
}
</style>

<style>
    ::-webkit-scrollbar {
        display: none;
    }
</style>