<template>
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="mb-0">
          <div class="card-header">

            <v-card>
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Produção dos enfermeiros</h6>
            </div>
              <v-row class="pa-3">
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="UnidadeSelected"
                      :items="unidadesList"
                      item-text="Unidade"
                      item-value="Id"
                      label="Filtrar pela unidade"
                      clearable
                      @change="getMedicos()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                    ref="menu"
                    v-model="menuInicio"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="dataInicio"
                            label="Data de Início"
                            prepend-icon="mdi-calendar"
                            value=""
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            type="date"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="dataInicio"
                            :active-picker.sync="activePickerInicio"
                            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="[getMedicos($event)]"
                        ></v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                        ref="menu"
                        v-model="menuFim"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                              v-model="dataFim"
                              label="Data de Fim"
                              prepend-icon="mdi-calendar"
                              readonly
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              type="date"
                              ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="dataFim"
                              :active-picker.sync="activePickerFim"
                              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                              min="1950-01-01"
                              @change="[getMedicos($event)]"
                          ></v-date-picker>
                    </v-menu>
                  </v-col>
              </v-row>
              <template>
                <v-data-table
                  :headers="header"
                  :items="listagem"
                  :items-per-page="10"
                  :search="search"
                  class="elevation-1"
                  :loading="loading"
                  loading-text="Carregando..."
                >
                </v-data-table>
              </template>
            </v-card>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import moment from 'moment'

export default {
    data () {
        return {
            listagem: [],
            unidadesList: null,
            UnidadeSelected: null,
            activePickerInicio: null,
            activePickerFim: null,
            dataInicio: moment(new Date()).format('YYYY-MM-DD'),
            dataFim: moment(new Date()).format('YYYY-MM-DD'),
            menuInicio: false,
            menuFim: false,
            medicos: [],
            loading: false,
            search: null,
            mediaPlantao:null,
            header:
            [
                {text:"CPF", value:"CPF", width:"15%"},
                {text:"Profissional", value:"Usuario", width:"16%"},
                {text:"Unidade", value:"Unidade", width:"10%"},
                {text:"CBO", value:"CBO", width:"10%"},
                {text:"CNS", value:"CNS", width:"10%"},
                {text:"Descrição", value:"Descricao", width:"16%"},
                {text:"Quantidade Atendimentos", value:"QtdAtendimento", width:"11%", align:"center"},
                {text:"Quantidade Plantões", value:"QtdPlantoes", width:"11%", align:"center"},
                {text:"Media Plantão", value:"MediaPlantao", width:"11%", align:"center"}
            ],
        }
      },
    methods: {
        async getUnidades(){
          const { data } = await this.axios.get('unidade', { params: { idUnidadeSetor: 2 } })
          this.unidadesList = data
        },

        changeHeaders(idUnidade){
          if (idUnidade == 95){
            this.header = 
            [
                {text:"CPF", value:"CPF", width:"15%"},
                {text:"Profissional", value:"Usuario", width:"20%"},
                {text:"Unidade", value:"Unidade", width:"20%"},
                {text:"CBO", value:"CBO", width:"10%"},
                {text:"CNS", value:"CNS", width:"15%"},
                {text:"Descrição", value:"Descricao", width:"16%"},
                {text:"Quantidade Atendimentos", value:"QtdAtendimento", width:"11%", align:"center"},
            ]
          }
          else{
            this.header = 
            [
                {text:"CPF", value:"CPF", width:"15%"},
                {text:"Profissional", value:"Usuario", width:"16%"},
                {text:"Unidade", value:"Unidade", width:"10%"},
                {text:"CBO", value:"CBO", width:"10%"},
                {text:"CNS", value:"CNS", width:"10%"},
                {text:"Descrição", value:"Descricao", width:"16%"},
                {text:"Quantidade Atendimentos", value:"QtdAtendimento", width:"11%", align:"center"},
                {text:"Quantidade Plantões", value:"QtdPlantoes", width:"11%", align:"center"},
                {text:"Media Plantão", value:"MediaPlantao", width:"11%", align:"center"}
            ]
          }
        },

        async getMedicos(){
          this.changeHeaders(this.UnidadeSelected);
         
            if(this.dataInicio && this.dataFim){
              this.loading = true
                await this.axios.get("/atendimento/producaoenfermeiros", {
                  params: {
                    dataInicial: this.dataInicio,
                    dataFinal: this.dataFim,
                    IdUnidade: this.UnidadeSelected
                  }
                }).then(res => {
                      this.listagem = [];
                      for(var l=0; l < res.data.length; l++){
                        this.MediaPlantao = 0;
                        if(res.data[l]['QtdAtendimento'] > 0 && res.data[l]['QtdPlantoes'] > 0){
                          this.MediaPlantao = res.data[l]['QtdAtendimento'] / res.data[l]['QtdPlantoes']
                          this.MediaPlantao = this.MediaPlantao.toFixed(2)
                        }

                        this.listagem.push({
                          ...res.data[l],
                          MediaPlantao: this.MediaPlantao
                        });

                      }


                  }).finally(() => {
                      this.loading = false
                  })
            }
          },   
     
    },
    mounted() {
        this.getMedicos()
        this.getUnidades()
    },
    
}
</script>
<style scoped>
::v-deep .custom-data-table thead th {
  color: white !important;
  background-color: #2fb1e5 !important;
  font-size: 14px !important;
}
</style>
