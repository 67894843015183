<template>
        <div class="page">
            <progress-system :isLoad="progress"></progress-system>
            <div class="container-fluid">
             <br>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                 <h1 class="h3 mb-0 text-gray-800">Meus atestados</h1>
                </div>

                <!-- <v-row>
                    <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                        <v-text-field
                            dense
                            outlined
                            clearable
                            hide-details
                            append-icon="mdi-magnify"
                            label="Nome da observação"
                            v-model="search"
                        ></v-text-field>    
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                        <v-select
                            hide-details
                            outlined
                            dense
                            clearable
                            v-model="searchAno"
                            :items="datas"
                            label="Filtrar pelo ano"
                            item-value="id"
                            item-text="ano"
                        >
                        </v-select>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol" >
                        <v-btn append-icon="mdi-autorenew" width="100%" v-if="search != null || Datafilter != null" @click="[clearFilters()]"
                            >
                            Limpar Filtros
                        </v-btn>
                    </v-col> 
                </v-row> -->
                <v-data-table
                    :headers="headers"
                    :items="listatestado"
                    :items-per-page="10"
                    class="elevation-1 custom-data-table"
                >
                <template v-slot:[`item.DataIns`]="{ item }">
                   <span> {{$moment(item.DataIns).format('L')}}</span>
                </template>
                <template v-slot:[`item.ApartirData`]="{ item }">
                   <span> {{$moment(item.ApartirData).format('L')}}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn @click="visualizarAtestado(item)" color="success" target="_blank"> Visualizar
                        <v-icon  target="_blank">mdi mdi-file</v-icon>
                    </v-btn>
                </template>
                </v-data-table>
            </div>
        </div>
</template>

<script>

import ProgressSystem from '../../components/ProgressSystem'

export default {
    name:'Atestados',
    components:{
        ProgressSystem
    },
    data: () => {
     return {
        dynamicCol: '',
        progress:true,
        listatestado:[],
        keys:[],
        values:[],
        headers:[
            {text:'Médico',value:'Medico'},
            {text:'A partir da data',value:'ApartirData'},
            {text:'Quantidade de dias',value:'QtdDIas'},
            {text:'Data',value:'DataIns'},
            {text:'Atestado',value:'actions', sortable: false},
         ],
        datas:[],
        
     }
    },
    methods:{
        getAtestados(){
            this.axios.get('atestado').then(res =>{
                this.listatestado = res.data;
            }).catch( e => {
                console.log(e.response);
            }).finally(()=>{
                this.progress = false;
            })
        },

        visualizarAtestado(item){
            
            this.keys.push("txtAtestadoSolic")
            this.values.push(item.IdAtestado);
						
            this.keys.push("txtIdMov");
            this.values.push(item.IdMov);

            this.openWindowWithPost('http://upa.macaiba.dti.systems/relatorios/fichaatestado.php', "Relatório", this.keys, this.values,"toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=yes,resizable=0,width=1024,height=768,top=30,left=30");

        },
         openWindowWithPost(url,name,keys,values,tamanhoWindows){
            var newWindow = window.open(url, name, tamanhoWindows);

            if (!newWindow) return false;

            var html = "";
            html += "<html><head></head><body><form id='formid' method='post' action='" + url +"'>";

            if (keys && values && (keys.length == values.length))
                for (var i=0; i < keys.length; i++)
                    html += "<input type='hidden' name='" + keys[i] + "' value='" + values[i] + "'/>";

            html += "</form><script type='text/javascript'>document.getElementById(\"formid\").submit()</sc"+"ript></body></html>";

            newWindow.document.write(html);
            return newWindow;
        }
        
    },
    mounted(){
        this.getAtestados();
    }

    
}
</script>
<style scoped>
::v-deep .custom-data-table thead th {
  color: white !important;
  background-color: #2fb1e5 !important;
  font-size: 14px !important;
}
</style>