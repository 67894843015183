<template>
    <div class="page">
        <progress-system :isLoad="progress"></progress-system>
        <div class="container-fluid">
            <br>
            <div class="d-sm-flex align-items-center justify-content-between">
                <h1 class="h3 mb-0 text-gray-800">Requerimentos</h1>
            </div>

            <v-row class="justify-end mr-0">
                <v-flex xs12 sm4 md3 lg3 xl2>
                    <v-btn
                        block
                        color="primary"
                        @click="
                            [(modalCadRequerimento = !modalCadRequerimento), editRequerimento = false, zerarCampos()]
                        "
                        dark
                    >
                        <v-icon left>mdi-plus</v-icon>
                        Novo Requerimento
                    </v-btn>
                </v-flex>
            </v-row>

            <v-data-table
                :headers="headers"
                :items="listaRequerimentos"
                :items-per-page="10"
                class="elevation-1 custom-data-table"
                :loading="progress"
                loading-text="Carregando..."
            >
                <template v-slot:[`item.DataLimite`]="{ item }">
                    <span>{{$moment(item.DataLimite).format('L')}}</span>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="
                                    [assinaRequerimento(item.IdProtocolo)]
                                "
                            >
                                <v-icon color="blue">mdi-draw</v-icon>
                            </v-btn>
                        </template>
                        <span>Assinar requerimento</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.editable">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="
                                    [(editRequerimento = true), modalCadRequerimento = true, loadEditarRequerimento(item.IdProtocolo)]
                                "
                            >
                                <v-icon color="blue">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar requerimento</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="!item.editable">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="
                                    [downloadPDFRequerimento(item.IdProtocolo)]
                                "
                            >
                                <v-icon color="blue">mdi-file-pdf</v-icon>
                            </v-btn>
                        </template>
                        <span>Baixar PDF do requerimento</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.editable">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="
                                    [deletaRequerimento(item.IdProtocolo)]
                                "
                            >
                                <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Excluir requerimento</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </div>

        <!-- CADASTRO/EDIÇÃO DE REQUERIMENTOS -->
        <v-dialog scrollable width="50%" v-model="modalCadRequerimento">
            <v-card :loading="progress">
                <v-card-title>
                    <v-row>
                        <v-col cols="12">
                            <h3 v-if="editRequerimento">Editar requerimento</h3>
                            <h3 v-if="!editRequerimento">Novo requerimento</h3>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="6">
                                <v-autocomplete
                                    v-model="cadRequerimento.IdTipo"
                                    :items="listaTiposProtocolo"
                                    item-text="Descricao"
                                    item-value="IdTipo"
                                    label="Tipo"
                                    @change="getTiposProtocolo($event)"
                                    :filter="filterTipos"
                                    :clearable=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <v-menu
                                    ref="menuDataLimite"
                                    v-model="menuDataLimite"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateFormatted"
                                            label="Data Limite"
                                            persistent-hint
                                            prepend-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date"
                                        no-title
                                        @input="
                                            menuDataLimite = false;
                                            dateFormatted = formatDate(date);
                                        "
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    auto-grow
                                    v-model="cadRequerimento.observacao"
                                    outlined
                                    label="Observação"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                @click="[(modalCadRequerimento = false), zerarCampos(), editRequerimento = false]"
                large
                dark
            >
                Cancelar
            </v-btn>
            <v-btn
                v-if="!editRequerimento"
                :loading="progress"
                @click="cadastraRequerimento()"
                color="primary"
                large
                dark
                >Cadastrar</v-btn
            >
            <v-btn
                v-if="editRequerimento"
                :loading="progress"
                @click="atualizaRequerimento(cadRequerimento.IdProtocolo)"
                color="primary"
                large
                dark
                >Salvar</v-btn
            >
            </v-card-actions>
        </v-card>
        </v-dialog>

        <system-snackbar :snackbar="snackbar"></system-snackbar>
    </div>
</template>

<script>
import jwtDecode from 'jwt-decode'
import SystemSnackbar from './../components/SystemSnackbar'
import ProgressSystem from '../components/ProgressSystem'

export default {
    name:'Requerimento',
    components:{
        SystemSnackbar,
        ProgressSystem
    },
    data: vm => ({
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        progress:true,
        date: new Date().toISOString().substr(0, 10),
        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        menuDataLimite: false,
        listaRequerimentos: [],
        mapListaRequerimentos: [],
        listaTiposProtocolo: [],
        headers:[
            {text:'Número', value:'numeroRequerimento', width:'10%'},
            {text:'Tipo de requerimento', value:'tipo.Descricao', width:'15%'},
            {text:'Nome do requerente', value:'pessoa.Nome', width:'20%'},
            //{text:'Data Limite', value:'DataLimite', width:'11%'},
            {text:'Observação', value:'Observacao', width:'30%'},
            {text:'Ações', value:'actions', width:'14%', align:'right' },
        ],
        modalCadRequerimento: false,
        editRequerimento: false,
        cadRequerimento: {
            IdTipo: null,
            dataLimite: null,
            observacao: ''
        }
    }),
    methods:{
        filterTipos(item, queryText, itemText) {
            const queryTextWithoutAccents = queryText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            const itemTextWithoutAccents = itemText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            return (
                itemTextWithoutAccents.match(queryTextWithoutAccents) !== null
            );
        },
        getRequerimentos(){
            this.axios.get('requerimento').then(res =>{
                this.listaRequerimentos = res.data;
                // mapeia ids dos protocolos para objetos
                res.data.forEach(item => {
                    this.mapListaRequerimentos[item.IdProtocolo] = item;
                });
            }).catch( () => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: "Não foi possível carregar os requerimentos",
                    color: 'red'
                }
            }).finally(()=> {
                this.progress = false;
            })
        },
        getTiposProtocolo(){
            this.axios.get("protocolotipo").then((res) => {
                this.listaTiposProtocolo = res.data;
                console.log(res.data);
            }).catch( e => {
                console.log(e.response);
            });
        },
        cadastraRequerimento(){
            this.cadRequerimento.dataLimite = this.date

            this.axios.post("requerimento", this.cadRequerimento)
            .then(() => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: "Requerimento cadastrado com sucesso!",
                    color: 'green'
                }
                this.zerarCampos();
                this.modalCadRequerimento = !this.modalCadRequerimento;
                this.getRequerimentos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data,
                    color: 'red',
                }
            })
        },
        loadEditarRequerimento(IdProtocolo){
            this.cadRequerimento.IdProtocolo = IdProtocolo;
            this.cadRequerimento.IdTipo = this.mapListaRequerimentos[IdProtocolo].IdTipo;
            this.cadRequerimento.dataLimite = this.mapListaRequerimentos[IdProtocolo].DataLimite;
            this.dateFormatted = this.formatDate(this.mapListaRequerimentos[IdProtocolo].DataLimite);
            this.date = this.mapListaRequerimentos[IdProtocolo].DataLimite;
            console.log(this.date);
            // this.date = this.parseDate(this.mapListaRequerimentos[IdProtocolo].DataLimite);
            this.cadRequerimento.observacao = this.mapListaRequerimentos[IdProtocolo].Observacao;
        },
        atualizaRequerimento(IdProtocolo){
            this.cadRequerimento.dataLimite = this.date

            this.axios.put(`requerimento/${IdProtocolo}/`, this.cadRequerimento)
            .then(() => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: "Requerimento atualizado com sucesso!",
                    color: 'green'
                }
                this.getRequerimentos()
                this.modalCadRequerimento = false;
                this.editRequerimento = false;
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data,
                    color: 'red'
                }
            })
        },
        deletaRequerimento(IdProtocolo){
            let del = confirm("Deseja realmente excluir esse requerimento?");

            if (del) {
                this.axios.delete(`requerimento/${IdProtocolo}/`)
                .then(() => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: "Requerimento excluído com sucesso!",
                        color: 'green'
                    }
                    this.getRequerimentos()
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data,
                        color: 'red'
                    }
                })
            }
        },
        downloadPDFRequerimento(IdProtocolo){
            this.axios.get(`requerimento/${IdProtocolo}/pdf`, {responseType: 'arraybuffer'})
            .then((response) => {
                let fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'requerimento.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();

                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: "Download realizado com sucesso!",
                    color: 'green'
                }
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data,
                    color: 'red'
                }
            })
        },
        assinaRequerimento(IdProtocolo){
            this.progress = true;
            this.axios.get(`requerimento/${IdProtocolo}/assinar`)
            .then(() => {
                this.progress = false;
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: "Requerimento assinado com sucesso!",
                    color: 'green'
                }
                this.getRequerimentos()
            }).catch(e => {
                this.progress = false;
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data,
                    color: 'red'
                }
            })
        },
        zerarCampos(){
            this.cadRequerimento.IdTipo = null
            this.cadRequerimento.dataLimite = null
            this.cadRequerimento.observacao = ''
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        getDadosUsuario() {
            if(this.$route.name != 'login'){
                this.axios.get('usuario').then(res => {
                this.usuario = res.data
                sessionStorage.setItem('usuario', JSON.stringify({
                    'Nome': res.data.Nome,
                    'IdPessoaFisica': res.data.IdPessoaFisica,
                    'Email': res.data.Email
                }))

                if(res.data.Diretor == 1 || jwtDecode(sessionStorage.getItem('token')).perfis['secretariaEscolar'] == true) {
                    sessionStorage.setItem('diretor', 'public')
                }else{
                    sessionStorage.setItem('diretor', 'private')
                }

                sessionStorage.setItem('unidade', res.data.IdUnidade)

                if(res.data.Professor == 1) {
                    sessionStorage.setItem('professor', 'public')
                }else{
                    sessionStorage.setItem('professor', 'secretariaEscolar')
                }

                if(res.data.Funcionario == 1) {
                    sessionStorage.setItem('funcionario', 'public')
                }else{
                    sessionStorage.setItem('funcionario', 'private')
                }

                if(res.data.Aluno == 1) {
                    sessionStorage.setItem('aluno', 'public')
                }else{
                    sessionStorage.setItem('aluno', 'private')
                }

                if(jwtDecode(sessionStorage.getItem('token')).perfis['admin'] == true){
                    sessionStorage.setItem('admin', 1)
                }else{
                    sessionStorage.setItem('admin', 0)
                }

                this.getDrawer()
                }).catch(e => {
                if(e.response.status == 401) {
                    sessionStorage.clear()
                }
                }).finally(()=>{

                })
            }else{
                console.log('nao pegou os dados')
            }
        },
    },
    mounted(){
        this.getRequerimentos()
        this.getTiposProtocolo()
    }
}
</script>

<style>
.theme--light.v-list-item .v-list-item__mask {
    color: rgba(0, 0, 0, 0.87) !important;
    background: #fff;
}
</style>