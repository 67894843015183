<template>
    <div>
        <v-data-table
            :items-per-page="50"
            :headers="headers"
            :items="item"
            item-key="Nome"
            class="table-rounded custom-data-table"
            hide-default-footer
            disable-sort>

            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="
                                openModal(item)
                            "
                            >
                                <v-icon color="primary">mdi-account-group</v-icon>
                            <span></span>
                        </v-btn>
                    </template>
                </v-tooltip>
            </template>
        </v-data-table>
        <div v-if="flag">
            <v-dialog 
                v-model="flag"
                width="900px"
                height="1200px">
                <v-card>
                    <div>
                        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                    </div>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Array
    },
    data() {
        return {
            headers: [
                { text: 'Nome da turma', value: 'Descricao', align:'left'},
                { text: 'Quantidade de alunos', value: 'QtdAlunos', align:'center'},
                //{ text: 'Ver Turmas', value:'actions', align:'center'}
            ],
            series: [
                {
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        borderRadius: 8,
                        horizontal: false,
                    },
                },
                xaxis: {
                    type: 'string',
                    categories: [],
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                },
                noData: {
                    align: "center",
                    text: "Nenhum dado disponível até o momento",
                    verticalAlign: "middle"
                },
            },
            item: [],
            flag: false,
            aux: []
        }       
    },
    methods:{
        openModal(item){
            this.flag = !this.flag
            this.series = [
                {
                    ...this.series[0], 
                    ...{
                        data: []
                    }
                }
            ]
            this.$props.data.filter(arr => {
                if(item.Descricao === arr.Descricao)
                    this.series[0].data.push(arr.QtdAlunos)
            })
        }
    },
    mounted(){
        this.item = Array.from(
            this.$props.data.reduce(
                (acc, { Descricao, QtdAlunos }) => acc.set(Descricao, (acc.get(Descricao) || 0) + QtdAlunos),
                new Map()
            ),
            ([Descricao, QtdAlunos]) => ({ Descricao, QtdAlunos })
        );
    }
}
</script>
<style scoped>
::v-deep .custom-data-table thead th {
  color: white !important;
  background-color: #2fb1e5 !important;
  font-size: 14px !important;
}
</style>