<template>
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="mb-0">
          <div class="card-header">

            <v-card>
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Produção dos médicos</h6>
            </div>
              <v-row class="m-2">
                <v-col align-self="center">
                  <v-autocomplete
                    v-model="UnidadeSelected"
                    :items="unidadesList"
                    item-text="Unidade"
                    item-value="Id"
                    label="Filtrar pela unidade"
                    clearable
                    @change="getMedicos()"
                  ></v-autocomplete>
                </v-col>
                <DataRange
                  :dateStart="dataInicio" 
                  :dateFinish="dataFim" 
                  @date:start="refreshStart"
                  @date:finish="refreshFinish"
                />
              </v-row>

              <v-data-table
                :headers="header"
                :items="listagemMedicos"
                :items-per-page="10"
                :search="search"
                class="elevation-1 custom-data-table"
                :loading="loading"
                loading-text="Carregando..."
              >
               <!--<template v-slot:[`item.ValorUnitario`] = "{ item }">
               <template> --> 
              </v-data-table>
            </v-card>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import moment from 'moment'
import DataRange from '@/components/DataRange'

export default {
    components: {
      DataRange
    },
    data () {
      return {
        unidadesList: null,
        UnidadeSelected: null,
        listagemMedicos: [],
        activePickerInicio: null,
        activePickerFim: null,
        dataInicio: moment().set('date', 1).format('YYYY-MM-DD'),
        dataFim: moment().format('YYYY-MM-DD'),
        menuInicio: null,
        menuFim: null,
        medicos: [],
        loading: false,
        search: null,
        mediaPlantao:null,
        header:
        [
            {text:"CPF", value:"cpf"},
            {text:"Nome do profissional", value:"nome"},
            {text:"CBO", value:"cbo"},
            {text:"Especialidade", value:"especialidade"},
            {text:"Atendimentos", value:"atendimentos", align:"center"},
            {text:"Presenças", value:"plantoes", align:"center"},
            {text:"Média de atendimentos por dia", value:"MediaPlantao", align:"center"}
        ]
      }
    },
    computed: {
      formatter(val) {
        return moment(val).format('DD/MM/YYYY')
      }
    },
    methods: {
      refreshStart(val) {
        this.dataInicio = val,
        this.getMedicos()
      },
      refreshFinish(val) {
        this.dataFim = val,
        this.getMedicos()
      },
      async getUnidades(){
        const { data } = await this.axios.get('unidade', { params: { idUnidadeSetor: 2 } })
        this.unidadesList = data
      },
      async getMedicos(){
        if(this.dataInicio && this.dataFim){
          this.loading = true
          await this.axios.get("/atendimento/producaomedicos", {
            params: {
              data_inicial: this.dataInicio,
              data_final: this.dataFim,
              IdUnidade: this.UnidadeSelected
            }
          }).then(res => {

            this.listagemMedicos = [];
          
            for(var l=0; l < res.data.length; l++){
              this.MediaPlantao = 0;
              if(res.data[l]['atendimentos'] > 0 && res.data[l]['plantoes'] > 0){
                this.MediaPlantao = res.data[l]['atendimentos'] / res.data[l]['plantoes']
                this.MediaPlantao = this.MediaPlantao.toFixed(2)
              }

              this.listagemMedicos.push({
                ...res.data[l],
                MediaPlantao: this.MediaPlantao
              });

            }
          }).finally(() => {
            this.loading = false
          })
        }
      },   
     
    },
    mounted() {
      this.getMedicos()
      this.getUnidades()
    },
    
}
</script>
<style scoped>
::v-deep .custom-data-table thead th {
  color: white !important;
  background-color: #2fb1e5 !important;
  font-size: 14px !important;
}
</style>
