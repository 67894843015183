<template>
    <div>
        <div class="container-fluid">
            <v-row>
                <v-dialog
                v-model="modal"
                width="900px"
                height="1200px"
                >
                <v-card  v-onClickaway="away">
                    <v-card-title>
                        <span class="text-h5">Detalhamento do elemento - {{this.nome_elemento}}</span>
                    </v-card-title>
                    <v-card-text>
                        <div class="sm">
                            <v-data-table class="mt-5 custom-data-table" :headers="headersModal" :items="detalhamentoRecDes" :search="search" :items-per-page="5">
                                <template v-slot:[`item.ValorEmpenhado`] = "{ item }">
                                        <span> {{item.ValorEmpenhado.toLocaleString('pt-br',{maximumFractionDigits:4,style: 'currency', currency: 'BRL'})}}</span>
                                </template>
                                <template v-slot:[`item.ValorLiquidado`] = "{ item }">
                                        <span> {{item.ValorLiquidado.toLocaleString('pt-br',{maximumFractionDigits:4,style: 'currency', currency: 'BRL'})}}</span>
                                </template>
                                <template v-slot:[`item.ValorPago`] = "{ item }">
                                        <span> {{item.ValorPago.toLocaleString('pt-br',{maximumFractionDigits:4,style: 'currency', currency: 'BRL'})}}</span>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="cancelModal()"
                        >
                        FECHAR
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>   
            <v-row>
                <v-col cols="12">
                    <v-card shaped elevation="1">
                        <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                            PREFEITURA MUNICIPAL DE MACAÍBA - DADOS DA TOPDOWN
                        </h4>
                        <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center">PAINEL DE INFORMAÇÕES FINANCEIRAS</h5>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-autocomplete
                    cols="12"
                    md="6"
                    class="ml-3 mr-3"
                    :items="anos"
                    v-model="ano"
                    label="Anos"
                    clearable
                    solo
                ></v-autocomplete>
                <v-autocomplete
                    cols="12"
                    md="6"
                    class="ml-3 mr-3"
                    :items="receitasdespesaslist"
                    v-model="codigoSecretaria"
                    label="Secretaria"
                    item-text="Secretaria"
                    item-value="CodigoSecretaria"
                    clearable
                    solo
                ></v-autocomplete>
                <v-autocomplete
                    cols="12"
                    md="6"
                    class="ml-3 mr-3"
                    :items="meses"
                    v-model="mes_inicial"
                    label="Mês inicial"
                    item-text="mes"
                    item-value="id"
                    clearable
                    solo
                ></v-autocomplete>
                <v-autocomplete
                    cols="12"
                    md="6"
                    class="ml-3 mr-3"
                    :items="meses"
                    v-model="mes_final"
                    label="Mês final"
                    item-text="mes"
                    item-value="id"
                    clearable
                    solo
                ></v-autocomplete>
            </v-row>
            <v-row>

                <v-col cols="12" md="12" weight="500" width="450">
                    <v-card>
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Comparativo de maiores receitas e despesas</h6>
                        </div>

                        <div>
                            <apexchart
                                type="line"
                                height="350"
                                :key='comparativoReceitaDespesaKey'
                                :options="comparativoReceitaDespesaOptions"
                                :series="comparativoReceitaDespesaSeries"
                            />
                        </div>
                    </v-card>
                </v-col>

            </v-row>
            <v-row>

                <v-col cols="12" md="6" weight="500" width="450">

                    <v-card>

                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Maiores receitas registradas</h6>
                        </div>

                        <div>
                            <apexchart
                                type="bar"
                                height="350"
                                :key='maioresReceitasKey'
                                :options="maioresReceitasOptions"
                                :series="maioresReceitasSeries"
                            />
                        </div>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6" weight="500" width="450">
                    <v-card>

                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Maiores despesas registradas</h6>
                        </div>

                        <div>
                            <apexchart
                                type="bar"
                                height="350"
                                :key='maioresDespesasKey'
                                :options="maioresDespesasOptions"
                                :series="maioresDespesasSeries"
                            />
                        </div>
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <div class="card-header py-3 mb-5 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Despesas e receitas por secretaria</h6>
                        </div>
                        <v-row>
                            <v-text-field
                                cols="12"
                                md="6"
                                class="ml-3 mr-3"
                                v-model="search"
                                prepend-icon="mdi-feature-search-outline"
                                label="Pesquisar"
                                clearable
                            ></v-text-field> 
                            <v-autocomplete
                                cols="12"
                                md="6"
                                class="ml-3 mr-3"
                                :items="receitasdespesaslist"
                                v-model="tipoDespesaReceita"
                                label="Tipo de transação"
                                item-text="Tipo"
                                item-value=""
                                @input="[getAllReceitasDespesas($event)]"
                                clearable
                            ></v-autocomplete>
                            <v-autocomplete
                                cols="12"
                                md="6"
                                class="ml-3 mr-3"
                                :items="receitasdespesaslist"
                                v-model="codElementoFiltro"
                                label="Tipo de elemento"
                                item-text="Elemento"
                                item-value="CodigoElemento"
                                @input="[getAllReceitasDespesas($event)]"
                                clearable
                            ></v-autocomplete>
                        </v-row>
                        <v-data-table class="elevation-1 custom-data-table" :headers="headers" :items="receitasdespesaslist" :search="search">

                            <template v-slot:[`item.ValorEmpenhado`] = "{ item }">
                                    <span> {{item.ValorEmpenhado.toLocaleString('pt-br',{maximumFractionDigits:4,style: 'currency', currency: 'BRL'})}}</span>
                            </template>
                            <template v-slot:[`item.ValorLiquidado`] = "{ item }">
                                    <span> {{item.ValorLiquidado.toLocaleString('pt-br',{maximumFractionDigits:4,style: 'currency', currency: 'BRL'})}}</span>
                            </template>
                            <template v-slot:[`item.ValorPago`] = "{ item }">
                                    <span> {{item.ValorPago.toLocaleString('pt-br',{maximumFractionDigits:4,style: 'currency', currency: 'BRL'})}}</span>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            icon
                                            @click="
                                                openModal(item),
                                                getdetalhamentoElemento(item)
                                            "
                                            >
                                                <v-icon color="primary">mdi-information-outline</v-icon>
                                            <span></span>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>

import formatter from "@/utils/formatter"
import { directive as onClickaway } from 'vue-clickaway'

export default {
    directives: {
      onClickaway: onClickaway
    },
    data() {
        return{
        mes_inicial: 1,
        mes_final: new Date().getMonth() + 1,
        search:"",
        receitasdespesaslist:[],
        detalhamentoRecDes: [],
        ano: 2022,
        mes: null,
        codigoSecretaria: null,
        tipoDespesaReceita: null,
        tipoElemento: null,
        codElementoFiltro: null,
        anos: [2020, 2021, 2022],
        modal: false,
        nome_elemento: null,

        headers:[
            {text:'Secretaria', value:'Secretaria', width:"30%", align:"left"},
            {text:'Nome do elemento', value:'Elemento', width:"30%", align:"left"},
            {text:'Código do elemento', value:'CodigoElemento', width:"10%", align:"left"},
            {text:'Tipo', value:'Tipo', width:"10%", align:"center"},
            {text:'Mês', value:'Mes', width:"5%", align:"center"},
            {text:'Valor Empenhado/Receita', value:'ValorEmpenhado', width:"15%", align:"right"},
            {text:'Valor Liquidado', value:'ValorLiquidado', width:"15%", align:"right"},
            {text:'Valor Pago', value:'ValorPago', width:"15%", align:"right"},
            {text:'Ações', value:'actions', width:"5%", align:"center"},
        ],
        headersModal:[
            {text:'Sub-elemento', value:'SubElemento', width:"20%", align:"left"},
            {text:'Ação', value:'Acao', width:"15%", align:"center"},
            {text:'Fonte', value:'Fonte', width:"15%", align:"center"},
            {text:'Valor Empenhado', value:'ValorEmpenhado', width:"15%", align:"right"},
            {text:'Valor Liquidado', value:'ValorLiquidado', width:"15%", align:"right"},
            {text:'Valor Pago', value:'ValorPago', width:"15%", align:"right"},
        ],
        comparativoReceitaDespesaSeries: [
            {
                name: "Receitas",
                data: []
            },
            {
                name: "Despesas",
                data: []
            }
        ],
        comparativoReceitaDespesaOptions: {

        colors: ['#25E6A5', '#F44336'],

        tooltip: {
            enabled: true,
            x: {
                show: false
            },
            y: {
                formatter: val => (val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}))
            }
        },
        chart: {
            height: 350,
            type: 'line',
            zoom: {
            enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        grid: {
            row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        },
        yaxis: {
            labels: {
                formatter: val => (val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}))
            }
        }
        },

        maioresReceitasSeries: [{
            name:'Valor',
            data: []
          }],
        maioresReceitasOptions: {
        
        tooltip: {
            enabled: true,
            x: {
                show: false
            },
            y: {
                formatter: val => (val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}))
            }
        },
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
            borderRadius: 4,
            horizontal: true,
            }
        },
        dataLabels: {
            enabled: true,
            formatter: val => (val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})),
            style: {
                colors: ["#000"]
            }
        },
        xaxis: {
            categories: [],
        }
        },

        maioresDespesasSeries: [{
            name: 'Valor',
            data: []
          }],
        maioresDespesasOptions: {

        tooltip: {
            enabled: true,
            x: {
                show: false
            },
            y: {
                formatter: val => (val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}))
            }
        },
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
            borderRadius: 4,
            horizontal: true,
            }
        },
        dataLabels: {
            enabled: true,
            formatter: val => (val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})),
            style: {
                colors: ["#000"]
            }
        },
        xaxis: {
            categories: [],
        }
        },

        comparativoReceitaDespesaKey: 0,
        maioresReceitasKey: 0,
        maioresDespesasKey: 0
    }
    },

    computed: {
        combinedFilterValues() {
            return this.ano + this.codigoSecretaria + this.mes_inicial + this.mes_final;
        },
        meses() {
            return [...Array(12).keys()].map(i => ({
                id: i + 1,
                mes: formatter.numberToMonth(i + 1)
            }))
        }
    },

    watch: {
        combinedFilterValues() {
            this.resetarcampos();

            this.getAllReceitasDespesas()
            this.getMaioresDespesas()
            this.getMaioresReceitas()
            this.getComparativoReceitasDespesas()
        }
    },

    methods: {
        resetarcampos() {
            this.maioresDespesasOptions.xaxis.categories = []
            this.maioresDespesasSeries[0].data = []
            
            this.maioresReceitasOptions.xaxis.categories = []
            this.maioresReceitasSeries[0].data = []
            
            this.comparativoReceitaDespesaSeries[0].data = []
            this.comparativoReceitaDespesaSeries[1].data = []
        },
        getAllReceitasDespesas(){
			this.axios.get('topDown', {
                params: {
                    ano: this.ano,
                    mes_inicial: this.mes_inicial,
                    mes_final: this.mes_final,
                    codSecretaria: this.codigoSecretaria,
                    tipoTransacao: this.tipoDespesaReceita,
                    codElemento: this.codElementoFiltro

                }
            }).then(res => {
                console.log(res.data)
                this.receitasdespesaslist = res.data
			})
        },
        getdetalhamentoElemento(item){
            console.log(item.CodigoElemento)
			this.axios.get('topDown/detalhamentoElemento', {
                params: {
                    ano: this.ano,
                    codSecretaria: item.CodigoSecretaria,
                    codElemento: item.CodigoElemento,
                    tipoTransacao: item.Tipo,
                    mes: item.Mes
                }
            }).then(res => {
                this.nome_elemento = item.Elemento
                this.detalhamentoRecDes = res.data
                console.log(this.detalhamentoRecDes)
			})
        },
        getMaioresDespesas(){
			this.axios.get('topDown/maioresDespesas', {
                params: {
                    ano: this.ano,
                    mes_inicial: this.mes_inicial,
                    mes_final: this.mes_final,
                    codSecretaria: this.codigoSecretaria
                }
            }).then(res => {
                for(var i=0; i < res.data.length; i++){
                    this.maioresDespesasSeries[0].data.push(res.data[i].ValorEmpenhado)
                    this.maioresDespesasOptions.xaxis.categories.push(res.data[i].Elemento)

                    // this.comparativoReceitaDespesaSeries[1].data.push(res.data[i].Valor)
                }
                this.maioresDespesasKey++;
			})
        },
        getMaioresReceitas(){
			this.axios.get('topDown/maioresReceitas', {
                params: {
                    ano: this.ano,
                    mes_inicial: this.mes_inicial,
                    mes_final: this.mes_final,
                    codSecretaria: this.codigoSecretaria
                }
            }).then(res => {
                for(var i=0; i < res.data.length; i++){
                    this.maioresReceitasSeries[0].data.push(res.data[i].ValorEmpenhado)
                    this.maioresReceitasOptions.xaxis.categories.push(res.data[i].Elemento)

                    // this.comparativoReceitaDespesaSeries[0].data.push(res.data[i].Valor)
                }
                this.maioresReceitasKey++;
			})
        },
        getComparativoReceitasDespesas(){
			this.axios.get('topDown/comparativoRecDes', {
                params: {
                    ano: this.ano,
                    codSecretaria: this.codigoSecretaria
                }
            }).then(res => {
                res.data.forEach(item =>
                {
                    this.comparativoReceitaDespesaSeries[
                        item.Tipo == 'Receita'
                            ? 0
                            : 1
                    ].data.push(
                        item.ValorEmpenhado
                    );

                    this.comparativoReceitaDespesaKey++;
                });
			})
        },
        openModal(item){
            this.modal = true
            console.log(item)
        },
        cancelModal() {
            this.modal = false
        }
    },
    mounted(){
        this.getAllReceitasDespesas()
        this.getMaioresDespesas()
        this.getMaioresReceitas()
        this.getComparativoReceitasDespesas()
    }
}
</script>
<style scoped>
::v-deep .custom-data-table thead th {
  color: white !important;
  background-color: #2fb1e5 !important;
  font-size: 14px !important;
}
</style>