var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"justify-center pt-2 mt-10"},[_c('v-card',{staticClass:"primary rounded",attrs:{"width":"95%"}},[_c('v-card-title',{staticClass:"primary",staticStyle:{"color":"white"}},[_vm._v(" Minhas turmas ")]),_c('v-data-table',{staticClass:"elevation-1 custom-data-table",attrs:{"headers":_vm.headersTurmas,"items":_vm.turmasList,"items-per-page":10},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#element'),expression:"'#element'"}],attrs:{"icon":"","left":""},on:{"click":function($event){[
                      _vm.boletimon = true,

                      _vm.getBoletim(item.IdMatricula),

                      _vm.getNome(item.Turma)
                      ]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-table-search ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Ver boletim ")])])]}}],null,true)})],1)],1),_c('SectionOne',{attrs:{"id":"element"}},[_c('v-row',{staticClass:"justify-center pt-6"},[(_vm.boletimon==false)?_c('v-alert',{staticClass:"black--text",attrs:{"width":"95%","color":"amber lighten-2","dark":"","dense":"","border":"left"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-school-outline ")]),_c('span',{staticClass:"pl-2"},[_vm._v(" Por favor, selecione ao menos uma "),_c('strong',[_vm._v(" turma ")]),_vm._v(" para visualizar seu boletim. ")])],1):_vm._e(),(_vm.boletimon==true)?_c('v-card',{staticClass:"primary rounded",attrs:{"width":"95%"}},[_c('v-card-title',{staticClass:"primary",staticStyle:{"color":"white"}},[_vm._v(" Boletim - "+_vm._s(this.serienome)+" "),_c('v-icon',{staticClass:"ml-auto",attrs:{"color":"white"},on:{"click":function($event){[_vm.fecharModal()]}}},[_vm._v(" mdi-close-circle-outline ")])],1),_c('v-data-table',{staticClass:"elevation-1 custom-data-table",staticStyle:{"width":"100%"},attrs:{"headers":_vm._headers,"items":_vm.disciplinasLista,"items-per-page":"20","footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right'
            }},scopedSlots:_vm._u([{key:`item.Nota1`,fn:function({ item }){return [(item.Nota1 < 6)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(item.Nota1)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.Nota1)+" ")])]}},{key:`item.Nota2`,fn:function({ item }){return [(item.Nota1 < 6)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(item.Nota2)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.Nota2)+" ")])]}},{key:`item.Nota3`,fn:function({ item }){return [(item.Nota3 < 6)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(item.Nota3)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.Nota3)+" ")])]}},{key:`item.Nota4`,fn:function({ item }){return [(item.Nota4 < 6)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(item.Nota4)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.Nota4)+" ")])]}},{key:`item.Ma`,fn:function({ item }){return [(item.Ma < 6)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(item.Ma)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.Ma)+" ")])]}},{key:`item.s`,fn:function({ item }){return [(item.Ma>=6)?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" APROVADO ")]):(item.Ma < 6 && item.r == null)?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(" RECUPERAÇÃO ")]):_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" REPROVADO ")])]}}],null,true)})],1):_vm._e()],1)],1),_c('v-btn',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],staticClass:"mx-2",attrs:{"fab":"","dark":"","fixed":"","bottom":"","small":"","right":"","color":"primary"},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }